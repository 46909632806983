import { Box, Button, Container, DialogContent, DialogTitle, Paper, Grid, IconButton, Stack, TableBody, Typography, Checkbox } from '@mui/material';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { cityOptions, stateOptions } from '../../../services/components/selectOptions.service';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { InputSelect } from '../../../core/forms/inputs/InputSelect';
import { InputText } from '../../../core/forms/inputs/InputText';
import { InputTextArea } from '../../../core/forms/inputs/InputTextArea';
import { addNewShippingAddressForm, EditOrderShippingAddressFormHandler } from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import { InputAddress } from '../../../core/forms/inputs/InputAddress';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { GET_PATIENT_ORDER_DETAIL } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { formatMessage } from '../../../constants/util';
import { BootstrapDialog, StyledTableCell, StyledTableRow } from '../../../core/tables/tableStyles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { MessagePopup } from '../MessagePopup';
import { CheckBox } from '@mui/icons-material';
import { PrescriptionPatientCards } from '../PrescriptionPatientCards';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import CheckIcon from '@mui/icons-material/Check';
interface Props {
  order: Order | null;
  handleEnrollAutoRefill: (order: Order) => void;
}

interface Prescription {
  price: string;
  comment: string;
  dosageInstructions: string;
  itemId: number;
  medicalNecessity: string;
  name: string;
  ndc: string;
  originalRefill: string;
  quantity: string;
  refillLeft: string;
  refillUsed: string;
  contentKey: string;
  rxId: number;
  isAutoRefill: number; // Ensure this property is included
}

interface Order {
  prescriptions: Prescription[];
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const AutoEnrollRefill = (props: { handleClose: (res?: boolean) => void; orderId: number }) => {
  const [order, setOrder] = useState<Order | undefined>();
  const [model, setModel] = useState<boolean>(false);
  const [prescriptionMap, setPrescriptionMap] = useState<{ [key: number]: boolean }>({});
  const [enrollmentStates, setEnrollmentStates] = useState<{ [key: string]: boolean }>({});
  const [unEnrollmentStates, setUnEnrollmentStates] = useState<{ [key: string]: boolean }>({});

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const [enrolledIds, setEnrolledIds] = useState<number[]>([]);
  const [unEnrolledIds, setUnEnrolledIds] = useState<number[]>([]);
  const [msg, setMsg] = useState<string>('Your requests have been successfully saved. If you need to make any changes, please reach out to Customer Care.');

  // Handle checkbox change
  /*    const handleCheckboxChange = (prescriptionId: number, isChecked: boolean) => {
           // Update the prescriptionMap based on the checkbox state
   
           // Update checked items state
           setCheckedItems(prevCheckedItems => ({
               ...prevCheckedItems,
               [prescriptionId]: isChecked,
           }));
       };
   
       const getCheckedItemsData = () => {
           const changedAutoRefillTrue = Object.keys(checkedItems)
               .filter(key => checkedItems[+key] && prescriptionMap[+key] !== true) // Items changed to checked
               .map(key => +key);
   
           const changedAutoRefillFalse = Object.keys(checkedItems)
               .filter(key => !checkedItems[+key] && prescriptionMap[+key] === true) // Items changed to unchecked
               .map(key => +key);
   
           console.log("test", changedAutoRefillFalse)
   
           return {
               autoRefillTrue: {
                   rxId: changedAutoRefillTrue,
                   autoRefill: true
               },
               autoRefillFalse: {
                   rxId: changedAutoRefillFalse,
                   autoRefill: false
               }
           };
       }; */

  const formattedData = {
    autoRefillTrue: {
      rxId: enrolledIds,
      autoRefill: true
    },
    autoRefillFalse: {
      rxId: unEnrolledIds,
      autoRefill: false
    }
  };

  const getOrder = async (orderId?: number) => {
    try {
      const res: AxiosResponse = await Patient.get(`${GET_PATIENT_ORDER_DETAIL}/${orderId}`);

      if (res.status === 200) {
        setOrder(res.data);

        setCheckedItems((prev) => {
          const newPrescriptions = res.data.prescriptions
            ?.filter((prescription: any) => prescription.refillLeft > '0')
            .reduce((acc: any, prescription: any) => {
              acc[prescription.rxId] = prescription.isAutoRefill === 1;
              return acc;
            }, {});
          return {
            ...prev,
            ...newPrescriptions
          };
        });

        setPrescriptionMap((prev) => {
          const newPrescriptions = res.data.prescriptions
            ?.filter((prescription: any) => prescription.refillLeft > '0')
            .reduce((acc: any, prescription: any) => {
              acc[prescription.rxId] = prescription.isAutoRefill === 1;
              return acc;
            }, {});
          return {
            ...prev,
            ...newPrescriptions
          };
        });
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  const handleEnrollAutoRefill = async () => {
    const promises = [];

    if (formattedData.autoRefillTrue.rxId.length > 0) {
      promises.push(
        Patient.post(`/patient-portal/order/enroll-unroll-autorefill`, formattedData.autoRefillTrue)
          .then((res: AxiosResponse) => {
            if (res.status === 201) {
              // Toast for auto refill successfully submitted
              setModel(true);
              getOrder(props.orderId);

              if (formattedData.autoRefillFalse.rxId.length < 1) {
                setMsg('Congratulations! You are now enrolled in auto refill. If you need to make any changes, please contact Customer Care.');
              }
            }
            return res;
          })
          .catch((err: any) => {
            if (err?.response?.data?.message) {
              toast(formatMessage(err?.response.data.message));
            }
            throw err; // Re-throw the error to handle it in Promise.all
          })
      );
    }

    if (formattedData.autoRefillFalse.rxId.length > 0) {
      promises.push(
        Patient.post(`/patient-portal/order/enroll-unroll-autorefill`, formattedData.autoRefillFalse)
          .then((res: AxiosResponse) => {
            if (res.status === 201) {
              // Toast for auto refill successfully submitted
              setModel(true);
              getOrder(props.orderId);
              if (formattedData.autoRefillTrue.rxId.length < 1) {
                setMsg('You have been successfully unenrolled from the auto refill. If you need to make any changes, please reach out to Customer Care.');
              }
            }
            return res;
          })
          .catch((err: any) => {
            if (err?.response?.data?.message) {
              toast(formatMessage(err?.response.data.message));
            }
            throw err; // Re-throw the error to handle it in Promise.all
          })
      );
    }

    try {
      await Promise.all(promises);
      // Additional logic if needed after all promises resolve
    } catch (error) {
      // Handle errors if needed
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder(props.orderId);
  }, [props.orderId]);

  const handleClose = () => {
    setModel(false);
    props.handleClose(false);
  };

  const handleCloseModel = () => {
    setModel(false);
    props.handleClose(false);
  };

  const unEnrollHandler = (prescriptionId: number) => {
    const newState = !unEnrollmentStates[prescriptionId];
    // Send rxId with the new state (true/false)
    console.log(`Sending ${prescriptionId} with state ${newState}`);

    // Update the enrollment state
    setUnEnrollmentStates({
      ...unEnrollmentStates,
      [prescriptionId]: newState
    });

    if (newState) {
      setUnEnrolledIds((prevIds) => [...prevIds, prescriptionId]);
    } else {
      setUnEnrolledIds((prevIds) => prevIds.filter((id) => id !== prescriptionId));
    }
  };

  const enrollHandler = (prescriptionId: number) => {
    const newState = !enrollmentStates[prescriptionId];
    // Send rxId with the new state (true/false)
    console.log(`Sending ${prescriptionId} with state ${newState}`);

    // Update the enrollment state
    setEnrollmentStates({
      ...enrollmentStates,
      [prescriptionId]: newState
    });

    if (newState) {
      setEnrolledIds((prevIds) => [...prevIds, prescriptionId]);
    } else {
      setEnrolledIds((prevIds) => prevIds.filter((id) => id !== prescriptionId));
    }
  };

  console.log(unEnrolledIds);
  console.log(enrolledIds);

  return (
    <>
      {model && (
        <BootstrapDialog onClose={() => handleCloseModel()} open={model} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <MessagePopup handleClose={(res?: boolean) => handleClose()} content={msg} />
        </BootstrapDialog>
      )}

      <DialogContent dividers className="popup_content">
        <Box component="main" className="card-info">
          <Container maxWidth="lg">
            <Box className="main-box" mt={4}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={1}>
                  <Typography className="heading_bottom_without_border">
                    <span className="profile_icon">
                      <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                    </span>
                    Auto Refill Status
                  </Typography>
                </Stack>
                <Box>
                  <TableContainer component={Paper} className="table_customized_Prescription2">
                    <Table sx={{ minWidth: 400, minHeight: 120 }} stickyHeader>
                      <TableHead className="table_head">
                        <TableRow>
                          <StyledTableCell>Medications eligible for auto-refill </StyledTableCell>

                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className="table_body">
                        {order &&
                          order.prescriptions?.map(
                            (prescription, index) =>
                              prescription.originalRefill > '0' && (
                                <StyledTableRow key={index}>
                                  <StyledTableCell component="td">
                                    <Stack style={{ lineBreak: 'auto', whiteSpace: 'normal' }}>
                                      {` ${prescription.name}`}
                                      <br />
                                      <Stack style={{ fontSize: 10, textTransform: 'none' }}>Current Status: {prescription.isAutoRefill == 1 ? 'Enrolled' : 'Un-enrolled'}</Stack>
                                    </Stack>
                                  </StyledTableCell>
                                  {prescription.isAutoRefill == 1 ? (
                                    <StyledTableCell component="td">
                                      <Stack>
                                        <Grid item className="Un-Enroll-box-container" xs={10} md={10} xl={10} lg={10}>
                                          {/*     {unEnrollmentStates[prescription.rxId] ? <CheckIcon style={{
                                                                                    position: "absolute", color: "#fff", zIndex: 9,
                                                                                    marginTop: 10,
                                                                                    marginRight: 8,
                                                                                    marginLeft: 10,
                                                                                    borderRadius: 50,
                                                                                    cursor: "pointer",
                                                                                }} onClick={() => unEnrollHandler(prescription.rxId)}
                                                                                /> : ""} */}
                                          <Button
                                            className={`right-box-btn-2 ${unEnrollmentStates[prescription.rxId] ? 'enrolled' : ''}`}
                                            onClick={() => unEnrollHandler(prescription.rxId)}
                                            style={{ background: unEnrollmentStates[prescription.rxId] ? '#f3893d' : '', color: unEnrollmentStates[prescription.rxId] ? '#fff' : '#f3893d' }}
                                          >
                                            <Stack style={{ position: 'absolute', fontSize: 8, marginTop: -19, textTransform: 'none' }}> {unEnrollmentStates[prescription.rxId] ? 'Selected for' : 'Click here to'}</Stack>
                                            <Stack style={{ paddingTop: 11, textTransform: 'none' }}>Un-enroll</Stack>
                                          </Button>
                                        </Grid>
                                      </Stack>
                                    </StyledTableCell>
                                  ) : (
                                    <StyledTableCell component="td">
                                      <Stack>
                                        <Grid item className="Enroll-box-container" xs={10} md={10} xl={10} lg={10}>
                                          {/*   {enrollmentStates[prescription.rxId] ?
                                                                                    <CheckIcon style={{
                                                                                        position: "absolute", color: "#fff", zIndex: 9,
                                                                                        marginTop: 10,
                                                                                        marginRight: 8,
                                                                                        marginLeft: 10,
                                                                                        borderRadius: 50,
                                                                                        cursor: "pointer",
                                                                                    }} onClick={() => enrollHandler(prescription.rxId)} />
                                                                                    : ""}   */}{' '}
                                          <Button
                                            className={`right-box-btn-2 ${enrollmentStates[prescription.rxId] ? 'enrolled' : ''}`}
                                            onClick={() => enrollHandler(prescription.rxId)}
                                            style={{ background: enrollmentStates[prescription.rxId] ? '#f3893d' : '', color: enrollmentStates[prescription.rxId] ? '#fff' : '#f3893d' }}
                                          >
                                            <Stack style={{ position: 'absolute', fontSize: 8, marginTop: -19 }}> {enrollmentStates[prescription.rxId] ? 'Selected for' : 'Click here to'}</Stack>

                                            <Stack style={{ paddingTop: 11 }}>Enroll</Stack>
                                          </Button>
                                        </Grid>
                                      </Stack>
                                    </StyledTableCell>
                                  )}
                                </StyledTableRow>
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>
                        <Box className="add_outerbtns bt-flex">
                          <Button
                            className="cancel_btn btn-css3 f-size"
                            variant="contained"
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              backgroundColor: '#00ACBA',
                              border: '1px solid #00ACBA',
                              borderRadius: '8px',
                              boxShadow: 'none',
                              textTransform: 'capitalize',
                              width: '20%',
                              height: '49px'
                            }}
                            onClick={() => handleEnrollAutoRefill()}
                          >
                            Save
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
    </>
  );
};
