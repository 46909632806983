import React, { useState, useEffect } from 'react';
import { Alert, Box, Button, Container, Grid, Stack, Typography, FormControl, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';

import TertiaryButton from '../../../core/buttons/tertiary-button';
import { ChangeEvent } from 'react';
import { FieldControl, FieldGroup, Validators } from 'react-reactive-form';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { patientRepresentativeForm, refillPreferenceForm, settingUpdatePatinetRepresentativeFormHandler, settingUpdateRefillPreferenceHandler, settingUpdateUserProfileHandler } from '../../../services/pages/settings/personalLoginForm';
import { personalLoginForm, personalUserForm } from '../../../services/pages/settings/personalLoginForm';
import {
    addNewShippingAddressForm,
    EditRefillOrderShippingAddressFormHandler,
    EditRefillOrderShippingAddressFormHandlerAsGuest,
    UpdateRefillOrderShippingAddressFormHandler,
    UpdateRefillOrderShippingAddressFormHandlerAsGuest
} from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import EmailInputIcon from '../../../core/forms/inputs/EmailInputIcon';
import { InputPhone } from '../../../core/forms/inputs/InputPhone';
import { InputText } from '../../../core/forms/inputs/InputText';
import Login from '../../../assets/icons/login.svg';
import PasswordInputIconlogin from '../../../core/forms/inputs/PasswordInputIconlogin';
import PhysicianDoctor from '../../../constants/api';
import { User } from '../../../models/User';
import cameraPic from '../../../assets/icons/camera.svg';
import { profileActions } from '../../../store/Actions';
import profilePic from '../../../assets/icons/user.svg';
import { useNavigate } from 'react-router-dom';
import Doctor from '../../../constants/grx-api';
import { InputSelect } from '../../../core/forms/inputs/InputSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { Color } from '../../../interfaces/Color';
import moment from 'moment';
import { InputEmail } from '../../../core/forms/InputEmail';
import { notificationOptions } from '../../../services/components/selectOptions.service';
import { AllergyAutoCompleteInput } from '../../../core/forms/inputs/AllergyAutoCompleteInput';
import Volate from '../../../assets/icons/volate.svg';
import rxIcon from '../../../assets/icons/rx_icon.svg';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import editIcon from '../../../assets/icons/deleat_edit.svg';
import Home from '../../../assets/icons/shipping_home.svg';
import { BootstrapDialog } from '../../../core/tables/tableStyles';
import { AddNewCard } from '../../../components/popup/AddCardRequestRefillAsGuest';
import { AddNewAddress } from '../../../components/popup/AddNewAddress';
import { EditShippingAddress } from '../../../components/popup/EditShippingAddress';
import { genderOptions } from '../../../services/components/selectOptions.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AxiosResponse } from 'axios';
import { DELETE_PATIENT_CARD, GET_ALLERGIES, GET_PATIENT_CARDS, SET_DEFAULT_CARD, GET_PATIENT_SHIPPING_ADDRESS, PATIENT_PROFILE_IMAGE_CHANGE, LINK_EXISTING_USER_PHONE, LINK_EXISTING_USER_EMAIL, SEND_SMS_OTP, SEND_EMAIL_OTP, UPDATE_EXPECTED_DATE } from '../../../constants/Endpoints';
import Patient from '../../../constants/grx-api';
import { toast } from 'react-toastify';
import { InputDateOfBirth } from '../../../core/forms/inputs/InputDateOfBirth';
import { isLoggedIn } from '../../../services/auth/auth.service';
import InputPassword from '../../../core/forms/inputs/InputPassword';
import { LinkPhoneNumber } from '../../../components/popup/LinkPhoneNumber';
import { LinkEmailAddress } from '../../../components/popup/LinkEmailAddress';
import { sendEmailOtpHandler, sendSmsOtpHandler } from '../../../services/auth/verifyAccount.service';
import { RadioInput } from '../../../core/forms/inputs/RadioInput';
import SecondaryButton from '../../../core/buttons/secondary-button';
import { InputDate } from '../../../core/forms/inputDate';
import { formatMessage } from '../../../constants/util';
import { debounce } from '@mui/material/utils';
import axios from 'axios';
import { forgotPasswordHandler, forgotPswForm } from '../../../services/auth/forgotPassword.service';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { FormBuilder } from 'react-reactive-form';
import PrimaryButton from '../../../core/buttons/primary-button';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { guestPaymentForm, GuestPaymentHandler } from '../../../services/pages/popup-form/AddNewCardForm.service';
import { OrderInterface } from '../../../interfaces/order';
import { PatientSignUpThreeForm } from '../../../services/auth/register.service';
import { MessagePopup } from '../MessagePopup';
function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }
        } {...other}>
            {children}
            {
                onClose ? (
                    <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }
                    }>
                        <CloseIcon />
                    </IconButton>
                ) : null}
        </DialogTitle>
    )
}

interface originalShippingAddress {
    address1: any;
    address2: any;
    city: any;
    state: any;
    zipCode: any;
}



const PayInfo = React.memo((props: { handleClose: (res?: boolean) => void, handleTabClick: (res?: number) => void, payOrderData: OrderInterface, orderId: Number }) => {
    const router = useNavigate();
    const user: User = useAppSelector((state: RootState) => state.profileReducer.user);
    const [mask, setMask] = useState('9999');
    const dispatch = useAppDispatch();
    const [cards, setCards] = useState<any>([]);
    const [shippingAddress, setShippingAdrress] = useState<any>([]);
    const [selectedCardInfo, setSelectedCardInfo] = useState(() => {
        // Initialize with the default card from the API data
        const defaultCard = cards.find((item: any) => item.default);
        return defaultCard ? { cardId: defaultCard.cardId, lastFourDigits: defaultCard.lastFourDigits, type: defaultCard.type } : null;
    });
    const [message, setmessage] = useState(false);

    const [selectedCardInfoData, setSelectedCardInfoData] = useState<{
        cardId: string;
        lastFourDigits: string;
        type: string;
    }>({
        cardId: "",
        lastFourDigits: "",
        type: "",
    });

    const [selectedCardInfoDefault, setSelectedCardInfoDefault] = useState<{
        cardId: string;
        lastFourDigits: string;
        type: string;
    }>({
        cardId: "",
        lastFourDigits: "",
        type: "",
    });



    // const [allergyOptions, setAllergyOptions] = useState([]);
    const [data, setData] = useState({

        card: false,
        newAddress: false,
        editAddress: false,
        linkPhoneNumber: false,
        linkEmailAddress: false
    });

    let autoRefillArray: number[] = []; // Declare autoRefillArray as an array of numbers

    const autoRefillItem = sessionStorage.getItem('AutoRefill');
    if (autoRefillItem !== null) {
        try {
            // Parse the item as JSON
            const parsedItem = JSON.parse(autoRefillItem);

            // Check if the parsed item is an array
            if (Array.isArray(parsedItem)) {
                autoRefillArray = parsedItem; // Assign parsedItem to autoRefillArray
            }
        } catch (error) {
            console.error('Error parsing AutoRefill from sessionStorage:', error);
        }
    }

    const autoRefillTrue = {
        rxId: autoRefillArray,
        autoRefill: true
    };

    const handleShowMessage = () => {
        setmessage(true)
    }

    // Get the current date
const selectedDate = new Date(); // This will always be the current date

// Add 7 days to the current date
const expectedDate = new Date(selectedDate);
expectedDate.setDate(expectedDate.getDate() + 8);

// Format the expected date to the desired output (e.g., YYYY-MM-DD)
const expectedBy = expectedDate.toISOString(); // "YYYY-MM-DD" format

    const selectDate = localStorage.getItem("OrderDate")


    const handleGuestPayment = async (e: React.SyntheticEvent) => {

        // Remove the slashes using .replace()
        const cleanedString = selectDate?.replace(/\\/g, ''); // Remove all backslashes
        const finalString = cleanedString?.replace(/^"|"$/g, ''); // Remove surrounding quotes
        try {
            const data = {
              orderId: props.orderId,
              expectedBy: selectDate ? finalString: expectedBy
            };
            const response = await PatientWithoutToken.post(UPDATE_EXPECTED_DATE, data);
            if (response.status === 201) {
                guestPaymentForm.patchValue({
                    pat_id: props?.payOrderData?.patient?.patientId,
        
                });
                const res = await GuestPaymentHandler(e, router, shippingAddress, selectedCardInfoData, props.orderId, handleShowMessage);
        
                // console.log('RES::', res);
                if (res === 'OK') {
                    PatientSignUpThreeForm.reset();
        
                    if (Array.isArray(autoRefillArray) && autoRefillArray.length > 0) {
                        const res = PatientWithoutToken.post(`/patient-portal/order/enroll-unroll-autorefill`, autoRefillTrue)
                            .then((res: AxiosResponse) => {
                                if (res.status === 201) {
                                    // Toast for auto refill successfully submitted
        
                                    toast.success('Congratulations! You are now enrolled in auto refill. If you need to make any changes, please contact Customer Care.');
                                }
                                return res;
                            })
                            .catch((err: any) => {
                                if (err?.response?.data?.message) {
                                    toast(formatMessage(err?.response.data.message));
                                }
                                throw err; // Re-throw the error to handle it in Promise.all
                            });
                    }
        
                    props.handleClose(true);
                    props.handleTabClick(2);
                }
            }
          } catch (error: any) {
            if (error.response.data.message) {
              toast(formatMessage(error.response.data.message));
              return;
            }
          }

     
    };

    const handleNewCard = () => {
        setData((oldData) => ({
            ...oldData,
            card: true
        }));
    };

    const handleCardClose = (e?: string | React.SyntheticEvent) => {
        if (e === 'OK') {
            getCards();
            setData((oldData) => ({
                ...oldData,
                card: false
            })); // As the user has added cards, so set the 'hasDefaultBillingCard' to true
        }
        setData((oldData) => ({
            ...oldData,
            card: false
        }));
    };

    const handlePopupClose = () => {
        setData((oldData) => ({
            ...oldData,
            card: false,
            newAddress: false,
            editAddress: false,
            linkPhoneNumber: false,
            linkEmailAddress: false
        }));
    };


    const handleSetDefaultCard = async (cardId: string) => {
        const selectedCard = cards.find((item: any) => item.cardId === cardId);

        if (selectedCard) {
            console.log(selectedCard);
            setShippingAdrress(selectedCard.billingAddress);
            setSelectedCardInfo({ cardId: selectedCard.cardId, lastFourDigits: selectedCard.lastFourDigits, type: selectedCard.type });
        }
    };

    const cardInfo = () => {
        cards.forEach((item: any) => {
            if (item.default) {
                setShippingAdrress(item?.billingAddress);
                setSelectedCardInfoDefault({ cardId: item.cardId, lastFourDigits: item.lastFourDigits, type: item.type });
            }
        });
    };

    useEffect(() => {
        cardInfo();
    }, [cards]);

    const getCards = async () => {

        let orders = JSON.parse(localStorage.getItem('Orders') || '[]');
        const orderIndex = orders.orders.findIndex((order: any) => order.order.orderId === props.orderId);
        setCards(orders.cards);
    };

    const setCardData = () => {
        if (selectedCardInfo) {
            setSelectedCardInfoData({ cardId: selectedCardInfo.cardId, lastFourDigits: selectedCardInfo?.lastFourDigits, type: selectedCardInfo?.type });
        } else {
            setSelectedCardInfoData({ cardId: selectedCardInfoDefault.cardId, lastFourDigits: selectedCardInfoDefault.lastFourDigits, type: selectedCardInfoDefault.type });
        }
    };

    useEffect(() => {
        setCardData();
    }, [selectedCardInfo, selectedCardInfoDefault]);


    let orders = JSON.parse(localStorage.getItem('Orders') || '[]');

    // Check if the order exists in the local storage by orderId
    const orderIndex = orders.orders.findIndex((order: any) => order.order.orderId === props.orderId);

    const handleDeleteCard = async (cardId: any) => {
        try {
            if (cardId) {
                const res: AxiosResponse = await PatientWithoutToken.delete(`${DELETE_PATIENT_CARD}/${cardId}/${orders.orders[orderIndex].patient.patientId}`);
                if (res.status === 200) {
                    let orders = JSON.parse(localStorage.getItem('Orders') || '[]');

                    // Find the cards array within the orders
                    if (orders.cards) {
                        // Filter out the deleted card by its cardId
                        orders.cards = orders.cards.filter((card: any) => card.cardId !== cardId);

                        // Save the updated orders back to localStorage
                        localStorage.setItem('Orders', JSON.stringify(orders));
                        getCards();
                        // Show success message
                        toast('Card deleted successfully');
                        setData((oldData) => ({
                            ...oldData,
                            card: true
                        }));

                    }
                }

            }
        } catch (err: any) {
            if (err?.response?.data?.message) {
                toast(formatMessage(err?.response.data.message));
                return;
            }
        }
    };

    useEffect(() => {
        getCards();

    }, [user]);


    const handleClose = () => {
        setmessage(false)
    }
    const OpenPay = () => {
        // setmessage(false)
    }

    return (
        <>
            {
                data.card && (
                    <BootstrapDialog open={data.card} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }
                    }>
                        <AddNewCard handleClose={handleCardClose} openPayInfo={OpenPay} orderId={props.orderId}
                        />
                    </BootstrapDialog>
                )}
            {
                message && (
                    <BootstrapDialog onClose={() => handleClose()} open={message} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }
                    }>
                        <MessagePopup handleClose={(res?: boolean) => handleClose()} content='There is some error in the payment, please try after sometime.' />
                    </BootstrapDialog>
                )}
            <DialogContent dividers className="popup_content" >
                <Box component="main" className="card-info" >
                    <Container maxWidth="lg" >
                        <Box className="main-box" mt={4} >
                            <Box sx={{ bgcolor: 'background.paper' }}>
                                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={3} >
                                    <Typography className="heading_bottom_without_border" >
                                        <span className="profile_icon" >
                                            <img src={Volate} alt="Imprimis RX A Harrow Company" width={16} />
                                        </span>
                                        Payment Info
                                    </Typography>
                                </Stack>
                                < Box >
                                    <Container maxWidth="xl" >
                                        <Stack direction="row" alignItems="center" >
                                            <Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={{ xs: 0, sm: 0, md: 0 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} >

                                                </Grid>
                                                < Grid item xs={12} sm={12} md={12} lg={10} p={0} >
                                                    <Grid className="setting_info" display="flex" direction="row" mb={4} >

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >

                                                            <Box className="info_section" >
                                                                <Grid container className="info_row" xs={12} sm={12} md={12} lg={12} spacing={3} >
                                                                    <Grid item className="info_card" xs={12} sm={12} md={12} lg={12} >

                                                                        <Grid className="info" >
                                                                            <Typography variant="subtitle2" sx={{ mb: 1, mt: 1 }}>
                                                                                In order to process your prescription, ImprimisRx requires a valid debit or credit card.
                                                                            </Typography>
                                                                            {
                                                                                cards.length < 1 && (
                                                                                    <Box className="handler_button" >
                                                                                        <SecondaryButton label={'Add'} customStyles={{ width: '100px', height: '45px' }
                                                                                        } onClick={handleNewCard} />
                                                                                    </Box>

                                                                                )}
                                                                            <Box className="card" >
                                                                                <Box className={cards?.length > 2 ? 'card-list scrollable-cards' : 'card-list'}>
                                                                                    <FormControl>
                                                                                        {
                                                                                            Array.isArray(cards) &&
                                                                                            cards?.map((card, index) => (
                                                                                                <>
                                                                                                    <Grid container sx={{ display: 'flex' }} xs={12} sm={12} md={12} lg={12} xl={12} mt={1} >
                                                                                                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} ml={1.5} >
                                                                                                            <FormControlLabel value={card.cardId} control={< Radio checked={card.default} value={card.cardId} style={{ display: "none" }}
                                                                                                                onChange={(e) => handleSetDefaultCard(e.target.value)} />} label={`${card.type} - xx${card.lastFourDigits}`} />
                                                                                                        </Grid>
                                                                                                        < Grid item sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                                                            <SecondaryButton label={'Delete And Add New Card'} customStyles={{ fontSize: '1rem', padding: 0, height: '40px', width: '250px', borderWidth: '1px' }} onClick={() => handleDeleteCard(card.cardId)} />
                                                                                                        </Grid>

                                                                                                    </Grid>
                                                                                                </>
                                                                                            ))}
                                                                                    </FormControl>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>


                                                                    </Grid>

                                                                </Grid>
                                                                < Stack display="flex" justifyContent="center" alignItems="center" mt={3} >
                                                                    <Grid container spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                                                        <Grid item xs={6} sm={6} md={6} lg={6} mt={2} >
                                                                            <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5} >


                                                                                <Box className="add_outerbtns bt-flex" >
                                                                                    <PrimaryButton label={'Submit Payment'}

                                                                                        onClick={handleGuestPayment} />

                                                                                </Box>
                                                                            </Stack>
                                                                        </Grid>
                                                                    </Grid>


                                                                </Stack>

                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Container>
                                </Box>
                            </Box>
                        </Box>
                    </Container>

                </Box >
            </DialogContent >
            < BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}> </BootstrapDialogTitle>
        </>
    )
});

export default PayInfo;
