import { FormBuilder, Validators } from 'react-reactive-form';

import { AUTH_DOCTOR_PORTAL, ADD_PATIENT_CARD, GUEST_PAYMENT } from '../../../constants/Endpoints';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import { toast } from 'react-toastify';
import { formatMessage } from '../../../constants/util';
import { OrderInterface } from '../../../interfaces/order';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { PatientSignUpThreeForm } from '../../auth/register.service';
export const addNewCardForm = FormBuilder.group({
  pat_id: ['', [Validators.required]],
  cc_number: ['', [Validators.required]],
  exp_date: ['', [Validators.required]],
  cvc: ['', [Validators.required]],
  cardholdername: ['', [Validators.required]],
  billingZc: ['', [Validators.required]],
  street: ['', Validators.required],
  city: ['', Validators.required],
  state: ['', Validators.required],
  country: ['US', Validators.required]
});

export const guestPaymentForm = FormBuilder.group({
  pat_id: ['', [Validators.required]],
  cc_number: ['', [Validators.required]],
  exp_date: ['', [Validators.required]],
  cvc: ['', [Validators.required]],
  cardholdername: ['', [Validators.required]],
  billingZc: ['', [Validators.required]],
  street: ['', Validators.required],
  city: ['', Validators.required],
  state: ['', Validators.required],
  country: ['US', Validators.required]
});

export const allergiesForm = FormBuilder.group({
  allergies: ['', [Validators.required]]
});

export const AddNewCardFormHandler = async (event: React.SyntheticEvent, router: NavigateFunction) => {
  try {
    event.preventDefault();
    addNewCardForm.controls.pat_id.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cc_number.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.exp_date.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cvc.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cardholdername.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.billingZc.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.country.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewCardForm.invalid) {
      return;
    }

    let cardData: any = {
      pat_id: addNewCardForm?.value?.pat_id || '',
      default: true,
      number: addNewCardForm?.value?.cc_number?.replace(/\s/g, '') || '',
      cvc: addNewCardForm?.value?.cvc || '',
      expirationMonth: addNewCardForm?.value?.exp_date.split('/')[0] || '',
      expirationYear: `20${addNewCardForm?.value?.exp_date.split('/')[1]}` || '',
      billingAddress: {
        address1: addNewCardForm?.value?.street || '',
        state: addNewCardForm?.value?.state || '',
        city: addNewCardForm?.value?.city || '',
        zipCode: addNewCardForm?.value?.billingZc || ''
      },
      address: {
        address1: addNewCardForm?.value?.street || '',
        state: addNewCardForm?.value?.state || '',
        city: addNewCardForm?.value?.city || '',
        zipCode: addNewCardForm?.value?.billingZc || ''
      }
    };

    const addCardRes: AxiosResponse = await Patient.post(ADD_PATIENT_CARD, cardData);
    if (addCardRes.status === 201) {
      toast(formatMessage('Card added successfully'));
      return 'OK';
    }
    return false;
  } catch (err: any) {
    if (Object.keys(err?.response?.data)) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

interface selectedCardInfo {
  cardId: string;
  lastFourDigits: string;
  type: string;
}

export const GuestPaymentHandler = async (event: React.SyntheticEvent, router: NavigateFunction, billingAddress: object, selectedCardInfo: selectedCardInfo, orderId: Number, handleShowMessage: (res?: boolean) => void) => {
  try {
    event.preventDefault();
    guestPaymentForm.controls.pat_id.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.cc_number.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.exp_date.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.cvc.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.cardholdername.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.billingZc.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    guestPaymentForm.controls.country.markAsTouched({ emitEvent: true, onlySelf: true });

    // if (guestPaymentForm.invalid) {
    //   return;
    // }

    let orders = JSON.parse(localStorage.getItem('Orders') || '[]');

    // Check if the order exists in the local storage by orderId
    const orderIndex = orders.orders.findIndex((order: any) => order.order.orderId === orderId);

    let email = localStorage.getItem('guestEmail');
    email = email ? email.replace(/^"(.*)"$/, '$1') : null;

    let cardData: any = {
      patientId: orders.orders[orderIndex].patient.patientId || '',
      email: email,
      orderId: orderId,
      allergies: localStorage.getItem('allergies')?.split(',') || ['No Known Allergies'],
      cardId: Number(selectedCardInfo.cardId),
      card: null,
      address: {
        ...billingAddress
      }
    };

    const addCardRes: AxiosResponse = await PatientWithoutToken.post(GUEST_PAYMENT, cardData);
    if (addCardRes.status === 201) {
      toast(formatMessage('Payment completed successfully'));
      localStorage.setItem('tab', '2');
      sessionStorage.clear();
      localStorage.removeItem('OrderDate');
      localStorage.removeItem('allergies');
      localStorage.removeItem('guestEmail');

      return 'OK';
    }
    return false;
  } catch (err: any) {
    if (Object.keys(err?.response?.data)) {
      if (
        err?.response.data.message === "The transaction was unsuccessful." || err?.response.data.message === "This transaction has been declined." || err?.response.data.message === "A duplicate transaction has been submitted."
      ) {
        handleShowMessage(true)
      } else {
        toast(formatMessage(err?.response.data.message));

      }
      return;
    }
  }
};
