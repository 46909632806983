//auth--->
export const REGISTER_STAFF = '/doctorapi/adddoctor?type=S';
export const REGISTER_DOCTOR = '/doctorapi/adddoctor?type=D';
export const REGISTER_DOCTOR_STEP_ONE = 'doctor-portal/auth/register-doctor-step-one';
export const REGISTER_DOCTOR_STEP_TWO = 'doctor-portal/auth/register-doctor-step-two';

export const REGISTER_STAFF_STEP_ONE = 'doctor-portal/auth/register-staff-step-one';
export const REGISTER_STAFF_STEP_TWO = 'doctor-portal/auth/register-staff-step-two';

// export const FORGOT_PASSWORD = '/doctor-portal/auth/initiate-reset-password';
export const RESET_PASSWORD = '/doctorapi/changepassword';
export const EMAIL_LOGIN = '/doctorapi/login';
export const EMAIL_LOGINV2 = '/doctor-portal/auth/login';
export const MOBILE_LOGIN = '/doctorapi/mobilelogin';

//dashboard-->
export const GET_STATISTICS = '/doctor-portal/dashboard/get-stats';
export const GET_RECENT_ORDERS = '/doctor-portal/order/rx-by-md-id-top-five';

export const GET_SLIDER_ORDERS = '/doctor-portal/doctor/advertisements';
export const PRODUCT_SPOTLIGHT = '/productapi/getproducts';
// export const GET_ALLERGIES = '/doctor-portal/doctor/allergies';
// https://mobileauth.imprimisrx.com/development/webservices/1.0.7/index.php/constantapi/getconstantsv1?api_key=9e969f89f4e42d8eb3ef56582c7a69c6&constant_id=1

//used for all pages-->
export const GET_SPECIALTIES = '/doctor-portal/doctor/specialties';

//patient --->
export const GET_CURRENT_PATIENT = '/doctor-portal/patient/all';
export const GET_SINGLE_PATIENT = '/doctor-portal/patient/single';
export const GET_SINGLE_PATIENT_PRESCRIPTIONS = '/doctor-portal/patient/prescriptions';
export const NEW_PATIENT = '/doctor-portal/patient/add';
// export const NEW_PATIENT_LOCAL = 'https://mobileauth.imprimisrx.com/development/webservices/1.0.7/index.php/patientapi/addpatient'; not in use
export const UPDATE_PATIENT = '/doctor-portal/patient/update';
export const SEARCH_PATIENT = '/doctor-portal/patient/search';
export const GET_AR_GROUPS = '/doctor-portal/order/ar-group-by-md-id';

//prescription --->
export const GET_PRESCRIPTION_HISTORY = '/doctor-portal/order/rx-by-md-id';
// export const GET_ORDER_DETAIL = '/doctor-portal/order/rx-calc-order-price-for-invoice';
export const GET_ORDER_DETAIL = '/doctor-portal/order/imprimis-get-order-details';
export const CREATE_PRISCRIPTION_IMAGE = '/grx/prescription/create-image';
export const CREATE_ORDER = '/doctor-portal/order/create-order-prescriptions-and-line-items';

//setting -->
//user
export const UPDATE_PRESCRIBER_PASSWORD = '/doctor-portal/auth/update-password';
export const UPDATE_PRESCRIBER_PROFILE = '/doctor-portal/auth/update-profile';

//prescriber
export const PRESCRIBER_PROFILE_GET = '/doctor-portal/auth/attached-doctor';
// export const PRESCRIBER_PROFILE_UPDATE  = "/doctorstaffapi/updatestaffdoctor";
export const PRESCRIBER_PROFILE_UPDATE = '/doctorapi/updatedoctor'; // staff update

//setting prescriber popup
export const ADD_NEW_PRESCRIBER = '/doctor-portal/auth/attach-doctor';

export const REMOVE_NEW_PRESCRIBER = '/doctorstaffapi/removestaffdoctor';
// export const ADD_NEW_SHIPPING_ADDRESS = 'https://mobileauth.imprimisrx.com/development/webservices/1.0.7/index.php/doctorapi/updatedoctor?type=D';
export const ADD_NEW_CARD = '/grx/card/create-card-for-grx-patient';
export const AUTH_DOCTOR_PORTAL = '/auth/doctor-portal';
// export const GET_PATIENT_CARDS = '/grx/card/get-cards-for-grx-patient';
// export const DELETE_PATIENT_CARD = '/grx/card/patient-card';

// Order tracking
export const GET_SHIPPING_CARRIER = '/doctor-portal/order/get-shipping-carrier';
export const FEDEX_TRACK_URL = 'https://www.fedex.com/fedextrack/?tracknumbers=';
export const STAMPS_COM_TRACK_URL = 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=';
export const UPS_TRACK_URL = 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=';

//product-->
// export const PRODUCT_CATALOG = 'https://mobileauth.imprimisrx.com/development/webservices/1.0.7/index.php/productapi/getproductsv1';
export const PRODUCT_CATALOG = '/doctor-portal/doctor/get-products';
export const PRODUCT_CATALOG_BLUE_VIEW = '/doctor-portal/doctor/blue-view-products';
export const PRODUCT_Preservative_FILTER = '/constantapi/getconstantsv1'; // not used anywhere
export const PRODUCT_DOSAGE_FILTER = '/doctor-portal/doctor/dosage-forms';
export const PRODUCT_CATALOG_CAT_FILTER = '/doctor-portal/doctor/product-categories';
// export const ADD_FAV_PRODUCT = '/doctorfavouriteproductapi/addfavouriteproduct';
export const ADD_FAV_PRODUCT = '/doctor-portal/doctor/add-to-favorite';
export const REMOVE_FAV_PRODUCT = '/doctor-portal/doctor/remove-from-favorite';

//used for images -->
export const LOCAL_PROFILE_ENV = 'https://mobileauth.imprimisrx.com/development/webservices/1.0.7/index.php/doctorapi/updatedoctor?type=D';
export const ASSET_BASE_URL = 'https://mobileauth.imprimisrx.com/development/webservices/images/originals';
export const UPDATE_PROFILE_PIC = '/doctorapi/updateprofilepic';

// facility endpoints
export const GET_FACILITIES = '/doctor-portal/order/ar-group-by-md-id';
export const ADD_FACILITY = '/doctor-portal/facility/add-facility';
export const FACILITY_PROFILE_UPDATE = '/doctor-portal/facility/update-facility';
export const DELETE_FACILITY = ''; // TODO
export const ADD_FACILITY_CARD = '/grx/card/create-card-for-grx-facility';
export const GET_FACILITY_CARD = '/grx/card/get-cards-for-grx-facility';
export const DELETE_FACILITY_CARD = '/grx/card/facility-card';

export const PROFILE = 'user';
export const UPDATE = 'auth';

// ==============================================

//guest
export const GUEST_LOGIN = '/patient-portal/order/get-unpaid-orders-as-a-guest';
export const GUEST_PAYMENT = '/patient-portal/order/pay-for-order-as-a-guest';

//auth--->
export const GET_EXISTING_PATIENT = '/patient-portal/auth/get-existing-patient-details';
export const REGISTER_PATIENT = '/patient-portal/auth/register';
export const GET_ALLERGIES = '/grx/allergy/list-allergies';
export const LINK_SMS_ACCOUNT = '/patient-portal/auth/link-sms-account';
export const LINK_EMAIL_ACCOUNT = '/patient-portal/auth/link-email-account';
export const SEND_SMS_OTP = '/patient-portal/auth/send-sms-otp';
export const SEND_EMAIL_OTP = '/patient-portal/auth/send-email-otp';
export const OTP_LOGIN = '/patient-portal/auth/login-with-sms-code';
export const LOGIN_VIA_SMS_OTP = '/patient-portal/auth/login-with-sms-code';
export const LOGIN_VIA_EMAIL_OTP = '/patient-portal/auth/login-with-email-code';
export const PASSWORD_LOGIN = '/patient-portal/auth/login-with-email-and-password';
export const LOGON_MAGIC_LINK = '/patient-portal/auth/send-email-link';
export const GET_USER_DETAILS_BY_TOKEN = '/patient-portal/auth/user-details';
export const FORGOT_PASSWORD = '/patient-portal/auth/send-reset-password-email';

// order -->
export const GET_PATIENT_ORDERS = '/patient-portal/order';
// export const GET_UNPAID_ORDERS = '/patient-portal/order/unpaid-orders';
// export const GET_SHIPPED_ORDERS = '/patient-portal/order/shipped-orders';
export const GET_DELIVERED_ORDERS = '/patient-portal/order/delivered-orders';
export const GET_PATIENT_ORDER_DETAIL = '/patient-portal/order/details';
export const PAY_INVOICE = '/patient-portal/order/pay-for-order';
export const UPDATE_EXPECTED_DATE = '/patient-portal/order/expected-by-order';

// patient cards
// export const GET_PATIENT_CARDS = '/patient-portal/card/get-cards';
// export const ADD_PATIENT_CARD = '/patient-portal/card';
// export const DELETE_PATIENT_CARD = '/patient-portal/card';
// export const SET_DEFAULT_CARD = '/patient-portal/card/set-default';

export const GET_PATIENT_CARDS = '/grx/card/get-cards-for-grx-patient';
export const ADD_PATIENT_CARD = '/grx/card/create-card-for-grx-patient';
export const DELETE_PATIENT_CARD = '/grx/card/patient-card';
export const SET_DEFAULT_CARD = '/grx/card/set-default';

// patient address
export const GET_PATIENT_SHIPPING_ADDRESS = '/patient-portal/address/get-shipping-address';
export const ADD_NEW_SHIPPING_ADDRESS = '';
export const UPDATE_SHIPPING_ADDRESS = '/patient-portal/address/update-shipping-address';
export const UPDATE_ORDER_SHIPPING_ADDRESS = '/patient-portal/order/update-order-shipping-address';

// patient profile update
export const PATIENT_PROFILE_IMAGE_CHANGE = '/patient-portal/auth/upload-profile-picture';
export const UPDATE_PATIENT_PROFILE = '/patient-portal/auth/update-profile';
export const LINK_EXISTING_USER_PHONE = '/patient-portal/auth/link-existing-user-to-sms-account';
export const LINK_EXISTING_USER_EMAIL = '/patient-portal/auth/link-existing-user-to-email-account';

// get patients
export const GET_PATIENTS_BY_NAME = '/patient-portal/patient/get-patients-by-name';
export const LOGIN_AS_CUSTOMER_SUPPORT = '/patient-portal/auth/login-as-customer-support';
