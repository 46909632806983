import { Box, Button, Container, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import { cityOptions, stateOptions } from '../../../services/components/selectOptions.service';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { InputSelect } from '../../../core/forms/inputs/InputSelect';
import { InputText } from '../../../core/forms/inputs/InputText';
import { InputTextArea } from '../../../core/forms/inputs/InputTextArea';
import { addNewShippingAddressForm, EditRefillOrderShippingAddressFormHandler, UpdateRefillOrderShippingAddressFormHandler } from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import { InputAddress } from '../../../core/forms/inputs/InputAddress';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { GET_PATIENT_ORDER_DETAIL } from '../../../constants/Endpoints';
import Patient from '../../../constants/grx-api';
import { formatMessage } from '../../../constants/util';
import { toast } from 'react-toastify';
import { MessagePopup } from '../MessagePopup';
import { BootstrapDialog } from '../../../core/tables/tableStyles';
import { Order } from '../../../models/Order';
import PrimaryButton from '../../../core/buttons/primary-button';

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface originalShippingAddress {
  address1: any;
  address2: any;
  city: any;
  state: any;
  zipCode: any;
}
export const RequestRefill = (props: { handleClose: (res?: boolean) => void; handleOpen: (res?: boolean) => void; orderId: number; rxId: Array<number>; originalShippingAddress: originalShippingAddress }) => {
  /*   const [order, setOrder] = useState<Order | undefined>();
      const [originalShippingAddress, setOriginalShippingAddress] = useState<ShippingAddress>(); */

  const handleUpdateShippingAddress = async () => {
    props.handleClose(false);

    props.handleOpen(true);
  };

  return (
    <>
      <DialogContent dividers className="popup_content">
        <Box component="main" className="card-info">
          <Container maxWidth="lg">
            <Box className="main-box" mt={4}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={3}>
                  <Typography className="heading_bottom_without_border">
                    <span className="profile_icon">
                      <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                    </span>
                    Edit Shipping Address
                  </Typography>
                </Stack>
                <Box>
                  <FieldGroup
                    control={addNewShippingAddressForm}
                    render={() => (
                      <form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={12}>
                            <FieldControl name="street" render={InputAddress} meta={{ name: 'street', value: 'street', helperText: 'Street is required', label: 'Street', placeholder: 'Please Enter Street', required: true }} />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="apt_suite" render={InputAddress} meta={{ name: 'apt_suite', value: 'apt_suite', helperText: 'Apt./Suite  is required', label: 'Apt./Suite', placeholder: 'Please Enter Apt./Suite' }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="city" render={InputAddress} meta={{ name: 'city', value: 'city', label: 'City', placeholder: 'Please Enter City', required: true }} />
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="state" render={InputAddress} meta={{ name: 'state', value: 'state', label: 'State', placeholder: 'Please Enter State', required: true }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FieldControl name="code" render={InputText} meta={{ name: 'code', value: 'code', helperText: 'Postal Code is required', label: 'Postal Code', placeholder: 'Please Enter Postal Code', required: true }} />
                          </Grid>

                          {/* <Grid item xs={12} md={12} lg={12}>
                            <FieldControl name="address" render={InputText} meta={{ name: 'address', value: 'address', helperText: 'Address’ Name is required', label: 'Address’ Name', placeholder: 'Please Enter Postal Code' }} />
                          </Grid> */}
                          <Grid container spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={6} sm={6} md={6} lg={6} mt={2}>
                              <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>
                                <Box className="add_outerbtns bt-flex">
                                  <PrimaryButton label={'Next'} onClick={handleUpdateShippingAddress} />
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
    </>
  );
};
