import { Box, Button, Container, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const MessagePopup = (props: { handleClose: (res?: boolean) => void; content: string }) => {
  return (
    <>
      <DialogContent dividers className="popup_content" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box component="main" className="card-info">
          <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box className="main-box">
              <Box sx={{ bgcolor: 'background.paper', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <Stack direction="row" justifyContent="center" alignItems="center" mt={3}>
                  <Typography className="heading_bottom_without_border">
                    {/*    <span className="profile_icon">
                                            <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                                        </span> */}
                  </Typography>
                </Stack>
                <Box style={{ textAlign: 'center' }}>{props.content}</Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>
                      <Box className="add_outerbtns bt-flex">
                        <Button
                          className="cancel_btn"
                          variant="contained"
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            backgroundColor: '#00ACBA',
                            border: '1px solid #00ACBA',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            textTransform: 'capitalize',
                            width: '100px',
                            height: '49px'
                          }}
                          onClick={() => props.handleClose(false)}
                        >
                          OK
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>{' '}
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
    </>
  );
};
