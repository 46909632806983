import { Box, Button, Container, DialogContent, DialogTitle, Paper, Grid, IconButton, Stack, TableBody, Typography, Checkbox } from '@mui/material';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { cityOptions, stateOptions } from '../../../services/components/selectOptions.service';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { InputSelect } from '../../../core/forms/inputs/InputSelect';
import { InputText } from '../../../core/forms/inputs/InputText';
import { InputTextArea } from '../../../core/forms/inputs/InputTextArea';
import { addNewShippingAddressForm, EditOrderShippingAddressFormHandler } from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import { InputAddress } from '../../../core/forms/inputs/InputAddress';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { GET_PATIENT_ORDER_DETAIL } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { formatMessage } from '../../../constants/util';
import { BootstrapDialog, StyledTableCell, StyledTableRow } from '../../../core/tables/tableStyles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { MessagePopup } from '../MessagePopup';
import { CheckBox } from '@mui/icons-material';
import { PrescriptionPatientCards } from '../PrescriptionPatientCards';
import CheckIcon from '@mui/icons-material/Check';
import PrimaryButton from '../../../core/buttons/primary-button';
interface Props {
  order: Order | null;
  handleEnrollAutoRefill: (order: Order) => void;
}

interface Prescription {
  price: string;
  comment: string;
  dosageInstructions: string;
  itemId: number;
  medicalNecessity: string;
  name: string;
  ndc: string;
  originalRefill: string;
  quantity: string;
  refillLeft: string;
  refillUsed: string;
  contentKey: string;
  rxId: number;
  isAutoRefill: number; // Ensure this property is included
}

interface Order {
  prescriptions: Prescription[];
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const RequestRefillDetail = (props: { handleClose: (res?: boolean) => void; handleDeliveryDate: (res: Array<number>) => void; orderId: number }) => {
  const [order, setOrder] = useState<Order | undefined>();
  const [model, setModel] = useState<boolean>(false);
  const [prescriptionMap, setPrescriptionMap] = useState<{ [key: number]: boolean }>({});
  const [enrollmentStates, setEnrollmentStates] = useState<{ [key: string]: boolean }>({});
  const [unEnrollmentStates, setUnEnrollmentStates] = useState<{ [key: string]: boolean }>({});

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const [unEnrolledIds, setUnEnrolledIds] = useState<number[]>([]);

  // const getOrder = async (orderId?: number) => {
  //     try {
  //         const res: AxiosResponse = await Patient.get(`${GET_PATIENT_ORDER_DETAIL}/${orderId}`);

  //         if (res.status === 200) {
  //             setOrder(res.data);

  //             setCheckedItems((prev) => {
  //                 const newPrescriptions = res.data.prescriptions
  //                     ?.filter((prescription: any) => prescription.refillLeft > "0")
  //                     .reduce((acc: any, prescription: any) => {
  //                         acc[prescription.rxId] = prescription.isAutoRefill === 1;
  //                         return acc;
  //                     }, {});
  //                 return {
  //                     ...prev,
  //                     ...newPrescriptions,
  //                 };
  //             });

  //             setPrescriptionMap((prev) => {
  //                 const newPrescriptions = res.data.prescriptions
  //                     ?.filter((prescription: any) => prescription.refillLeft > "0")
  //                     .reduce((acc: any, prescription: any) => {
  //                         acc[prescription.rxId] = prescription.isAutoRefill === 1;
  //                         return acc;
  //                     }, {});
  //                 return {
  //                     ...prev,
  //                     ...newPrescriptions,
  //                 };
  //             });

  //         }
  //     } catch (err: any) {
  //         if (err?.response?.data?.message) {
  //             toast(formatMessage(err?.response.data.message));
  //             return;
  //         }
  //     }
  // };

  // useEffect(() => {
  //     getOrder(props.orderId);
  // }, [props.orderId]);

  let orders = JSON.parse(localStorage.getItem('Orders') || '[]');

  const orderIndex = orders.orders.findIndex((order: any) => Number(order.order.orderId) === props.orderId);

  // Check if the order exists in the local storage by orderId

  console.log(orders, orderIndex, props.orderId);

  const handleClose = () => {
    setModel(false);
    props.handleClose(false);
  };

  const handleCloseModel = () => {
    setModel(false);
    props.handleClose(false);
  };

  const handleNext = () => {
    if (unEnrolledIds.length > 0) {
      props.handleDeliveryDate(unEnrolledIds);
    } else {
      toast('Please select medication to continue');
    }
  };

  const unEnrollHandler = (prescriptionId: number) => {
    const newState = !unEnrollmentStates[prescriptionId];
    // Send rxId with the new state (true/false)

    // Update the enrollment state
    setUnEnrollmentStates({
      ...unEnrollmentStates,
      [prescriptionId]: newState
    });

    if (newState) {
      setUnEnrolledIds((prevIds) => [...prevIds, prescriptionId]);
    } else {
      setUnEnrolledIds((prevIds) => prevIds.filter((id) => id !== prescriptionId));
    }
  };

  return (
    <>
      {model && (
        <BootstrapDialog onClose={() => handleCloseModel()} open={model} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <MessagePopup handleClose={(res?: boolean) => handleClose()} content="Congratulations! You are now enrolled in auto refill. If you need to make any changes, please contact Customer Care." />
        </BootstrapDialog>
      )}

      <DialogContent dividers className="popup_content">
        <Box component="main" className="card-info">
          <Container maxWidth="lg">
            <Box className="main-box" mt={4}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center" mt={1}>
                  <Typography className="heading_bottom_without_border">
                    <span className="profile_icon">
                      <img src={Home} alt="Imprimis RX A Harrow Company" width={16} />
                    </span>
                    Request Refill{' '}
                  </Typography>
                </Stack>
                <Box>
                  <TableContainer component={Paper} className="table_customized_Prescription2">
                    <Table sx={{ minWidth: 400, minHeight: 120 }} stickyHeader>
                      <TableHead className="table_head">
                        <TableRow>
                          <StyledTableCell>Medications Eligible for Refill </StyledTableCell>

                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className="table_body">
                        {orders.orders[orderIndex] &&
                          orders.orders[orderIndex].prescriptions?.map((prescription: any, index: any) =>
                            prescription.originalRefill > '0' ? (
                              <StyledTableRow key={index}>
                                <StyledTableCell component="td">
                                  <Stack style={{ lineBreak: 'auto', whiteSpace: 'normal' }}>{` ${prescription.name}`}</Stack>
                                </StyledTableCell>
                                <StyledTableCell component="td">
                                  <Stack>
                                    <Grid item className="Enroll-box-container" xs={10} md={10} xl={10} lg={10}>
                                      {/*  {unEnrollmentStates[prescription.rxId] ? <CheckIcon style={{
                                                                                position: "absolute", color: "#fff", zIndex: 9,
                                                                                marginTop: 10,
                                                                                marginRight: 8,
                                                                                marginLeft: 10,
                                                                                fontWeight:600,
                                                                                borderRadius: 50
                                                                            }} /> : ""} */}
                                      <Button
                                        className={`request-right-box-btn-2 ${unEnrollmentStates[prescription.rxId] ? 'enrolled' : ''}`}
                                        onClick={() => unEnrollHandler(prescription.rxId)}
                                        style={{ background: unEnrollmentStates[prescription.rxId] ? '#f3893d' : '', color: unEnrollmentStates[prescription.rxId] ? '#fff' : '#f3893d' }}
                                      >
                                        <Stack style={{ position: 'absolute', fontSize: 8, marginTop: -19, textTransform: 'none' }}> {unEnrollmentStates[prescription.rxId] ? 'Selected for' : 'Click here to'}</Stack>
                                        <Stack style={{ paddingTop: 11, textTransform: 'none' }}>Request Refill</Stack>{' '}
                                      </Button>
                                    </Grid>
                                  </Stack>
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              <Grid display={'flex'} textAlign={'center'} alignItems={'center'} pt={2}>
                                No medications available for request refill.
                              </Grid>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={6} sm={6} md={6} lg={6} mt={2}>
                      <Stack className="add_btnouter" direction="row" justifyContent="center" alignItems="center" gap={1.5}>
                        <Box className="add_outerbtns bt-flex">
                          <PrimaryButton label={unEnrolledIds.length > 0 ? 'Next' : 'Select Medication'} onClick={handleNext} />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
    </>
  );
};
