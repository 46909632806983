import { Button, ButtonProps, styled } from '@mui/material';

import { MouseEventHandler } from 'react';
import { purple } from '@mui/material/colors';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  fontSize: 17,
  fontWeight: 600,
  backgroundColor: '#00ACBA',
  border: '1px solid #00ACBA',
  borderRadius: '8px',
  boxShadow: 'none',
  minWidth: 120,
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#00ACBA',
    border: '1px solid #00ACBA',
    boxShadow: 'none'
  },
  '&.MuiButton-root': {
    padding: 7
  }
}));

const PrimaryButton = (props: {
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: 'button' | 'submit' | 'reset'; // Optional type prop
}) => {
  return (
    <ColorButton onClick={props.onClick} type={props.type} className="primary-button" variant="contained" sx={{ width: '100%' }}>
      {props?.label}
    </ColorButton>
  );
};

export default PrimaryButton;
