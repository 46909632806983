import { FormBuilder, Validators } from 'react-reactive-form';
import { OTP_LOGIN, PASSWORD_LOGIN, SEND_SMS_OTP, SEND_EMAIL_OTP, LOGON_MAGIC_LINK, LOGIN_VIA_SMS_OTP, LOGIN_VIA_EMAIL_OTP, LOGIN_AS_CUSTOMER_SUPPORT } from '../../constants/Endpoints';

import { AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import Patient from '../../constants/grx-api';
import { toast } from 'react-toastify';
import { User } from '../../models/User';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { formatMessage } from '../../constants/util';

// ---------- LOGIN VIA SMS OTP --------------

export const PatientLoginFormSmsOtp = FormBuilder.group({
  phone: ['', [Validators.required]],
  area_code: ['', [Validators.required]]
});

export const PatientLoginSendSmsOtp = async (router: NavigateFunction) => {
  try {
    PatientLoginFormSmsOtp.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });

    if (PatientLoginFormSmsOtp.controls.phone.invalid) {
      return;
    }

    const phoneNumberDigits = PatientLoginFormSmsOtp?.value?.phone?.replace(/\D/g, '');
    const area_code = phoneNumberDigits.slice(0, 3);
    const number = phoneNumberDigits.slice(3);

    const data = {
      areaCode: area_code,
      number: number
    };

    const res: AxiosResponse = await PatientWithoutToken.post(SEND_SMS_OTP, data);
    if (res.status === 201) {
      localStorage.setItem('areaCode', area_code);
      localStorage.setItem('number', number);
      PatientLoginFormSmsOtp.reset();
      return router('/confirm-otp', { state: { loginMode: 'phone' } });
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

export const PatientLoginSmsOtpHandler = async (otp: string) => {
  try {
    const areaCode = localStorage.getItem('areaCode');
    const number = localStorage.getItem('number');

    const data = {
      phoneNumber: {
        areaCode: areaCode,
        number: number
      },
      code: otp
    };

    const res = await PatientWithoutToken.post(LOGIN_VIA_SMS_OTP, data);
    if (res.status === 201 || res.status === 200) {
      return res?.data;
    }
  } catch (error: any) {
    if (error?.response?.data?.message) {
      toast(formatMessage(error?.response.data.message));
      return false;
    }
  }
};

// ----------- LOGIN VIA EMAIL AND PASSWORD --------------
export const PatientLoginFormPassword = FormBuilder.group({
  email: ['', [Validators.required]],
  password: ['', [Validators.required]]
});

export const PatientLoginFormPasswordHandler = async (router: NavigateFunction, data?: any) => {
  try {
    PatientLoginFormPassword.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientLoginFormPassword.controls.password.markAsTouched({ emitEvent: true, onlySelf: true });

    if (PatientLoginFormPassword.controls.email.invalid || PatientLoginFormPassword.controls.password.invalid) {
      return;
    }

    window.localStorage.removeItem('isLoggedIn');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('idToken');
    window.sessionStorage.removeItem('isSessionOngoing');
    window.localStorage.removeItem('User');

    const data = {
      email: PatientLoginFormPassword.value.email,
      password: PatientLoginFormPassword.value.password
    };

    let res: AxiosResponse = await PatientWithoutToken.post(PASSWORD_LOGIN, data);
    if (res.status === 201) {
      sessionStorage.setItem('isSessionOngoing', 'true');
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('idToken', res?.data?.idToken);
      localStorage.setItem('accessToken', res?.data?.accessToken);
      localStorage.setItem('refreshToken', res?.data?.refreshToken);
      const billingPopup = res?.data?.hasDefaultBillingCard ? '1' : '0';
      localStorage.setItem('billingPopup', billingPopup);
      toast(formatMessage('Loggedin Successfully'));
      PatientLoginFormPassword.reset();
      return User.create(res?.data, true);
    }
  } catch (err: any) {
    if (err?.response?.data?.statusCode === 450) {
      const tempData = {
        email: PatientLoginFormPassword.value.email,
        password: PatientLoginFormPassword.value.password
      };
      localStorage.setItem('tempUser', JSON.stringify(tempData));
      return err?.response?.data?.statusCode;
    }
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

// ------------ LOGIN VIA EMAIL OTP ---------------
export const PatientLoginFormEmailOtp = FormBuilder.group({
  email: ['', [Validators.required]]
});

export const PatientLoginSendEmailOtp = async (router: NavigateFunction) => {
  try {
    PatientLoginFormEmailOtp.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });

    if (PatientLoginFormEmailOtp.controls.email.invalid) {
      return;
    }

    const email = PatientLoginFormEmailOtp?.value?.email;

    const res: AxiosResponse = await PatientWithoutToken.post(`${SEND_EMAIL_OTP}/${email}`);

    if (res.status === 201) {
      localStorage.setItem('email', email);
      PatientLoginFormEmailOtp.reset();
      return router('/confirm-otp', { state: { loginMode: 'email' } });
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

export const PatientLoginEmailOtpHandler = async (otp: string) => {
  try {
    const email = localStorage.getItem('email');

    const data = {
      email: email,
      code: otp
    };

    const res = await PatientWithoutToken.post(LOGIN_VIA_EMAIL_OTP, data);
    if (res.status === 201 || res.status === 200) {
      return res?.data;
    }
  } catch (error: any) {
    if (error?.response?.data?.message) {
      toast(formatMessage(error?.response.data.message));
      return false;
    }
  }
};

// ------------ LOGIN VIA EMAIL OTP ---------------
export const PatientLoginMagicLink = FormBuilder.group({
  email: ['', [Validators.required]]
});

export const PatientLoginMagicLinkHandler = async (router: NavigateFunction, data?: any) => {
  try {
    PatientLoginMagicLink.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });

    if (PatientLoginMagicLink.controls.email.invalid) {
      return;
    }

    let res: AxiosResponse = await PatientWithoutToken.post(`${LOGON_MAGIC_LINK}/${PatientLoginMagicLink.value.email}`);
    if (res.status === 201) {
      toast(formatMessage('Email sent successfully'));
      PatientLoginMagicLink.reset();
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

// ------------ CUSTOMER SUPPOORT LOGIN ---------------
export const CustomerSupportLogin = FormBuilder.group({
  email: ['', [Validators.required]],
  password: ['', [Validators.required]],
  patientId: ['', [Validators.required]]
});

export const CustomerSupportLoginHandler = async (router: NavigateFunction) => {
  try {
    CustomerSupportLogin.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    CustomerSupportLogin.controls.password.markAsTouched({ emitEvent: true, onlySelf: true });
    CustomerSupportLogin.controls.patientId.markAsTouched({ emitEvent: true, onlySelf: true });

    if (CustomerSupportLogin.controls.email.invalid || CustomerSupportLogin.controls.password.invalid || CustomerSupportLogin.controls.patientId.invalid) {
      return;
    }

    const data = {
      email: CustomerSupportLogin.value.email,
      password: CustomerSupportLogin.value.password,
      patientId: CustomerSupportLogin.value.patientId
    };

    let res: AxiosResponse = await PatientWithoutToken.post(LOGIN_AS_CUSTOMER_SUPPORT, data);
    if (res.status === 201) {
      sessionStorage.setItem('isSessionOngoing', 'true');
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('idToken', res?.data?.idToken);
      localStorage.setItem('accessToken', res?.data?.accessToken);
      localStorage.setItem('refreshToken', res?.data?.refreshToken);
      localStorage.removeItem('tempUser');
      toast(formatMessage('Loggedin Successfully'));
      CustomerSupportLogin.reset();
      return User.create(res?.data, true);
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};
