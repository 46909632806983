import { AppBar, Box, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, Grid } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';

import Dot from '../../assets/icons/top_dot.svg';
import MenuItem from '@mui/material/MenuItem';
import SwipeableTemporaryDrawer from '../swipedrawer/drawer';
import Uparrow from '../../assets/icons/pol.svg';
import { User } from '../../models/User';
import cartIconRX from '../../assets/icons/cart-rx.svg';
import chevronright from '../../assets/icons/chevron-right.svg';
import gear from '../../assets/icons/gear.svg';
import logoUrl from '../../assets/icons/logo.svg';
import logout from '../../assets/icons/logout.svg';
import { profileActions } from '../../store/Actions';
import starfill from '../../assets/icons/star-fill.svg';
import { toast } from 'react-toastify';
import { useState } from 'react';
import hamburgerIcon from '../../assets/icons/hamburger.svg';
import callUsIcon from '../../assets/icons/mobile.svg';
import { formatMessage } from '../../constants/util';

const Header = () => {
  const router = useNavigate();

  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useAppDispatch();

  const user: User = useAppSelector((state) => state.profileReducer.user);

  const handleClick = () => {
    if (document.body.clientWidth > 1000) {
      setOpenSidebar(false);
      setOpen(!open);
    } else {
      setOpenSidebar(!openSidebar);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSidebar(false);
  };

  const logoutHandler = async () => {
    try {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('idToken');
      sessionStorage.removeItem('isSessionOngoing');
      localStorage.removeItem('User');
      localStorage.removeItem('billingPopup');
      dispatch(profileActions.setLogin(false));
      window.location.reload();
      router('/');
    } catch (err) {
      console.error(err);
      toast(formatMessage('Unable to remove Login details from device storage'));
    }
  };

  const gotoDashboard = () => {
    router('/home/dashboard');
  };

  const settingHandler = () => {
    router('/home/account');
  };

  return (
    <>
      <AppBar position="absolute" className="site-header">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ position: 'relative' }}>
            <Box>
              <Stack direction="row" alignItems="center">
                <Box className="navbar-brand">
                  <img src={logoUrl} alt="Imprimis RX A Harrow Company" width={150} onClick={gotoDashboard} />
                </Box>
                <Box className="navbar-nav">
                  <ul>
                    {user && (
                      <>
                        <li>
                          <NavLink to="/home/dashboard" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                            <div>
                              <img src={Dot} alt="Imprimis RX A Harrow Company" />
                            </div>
                            Pending Prescriptions
                          </NavLink>
                        </li>
                      </>
                    )}
                    <li>
                      <NavLink to="/home/prescriptions" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                        <div>
                          <img src={Dot} alt="Imprimis RX A Harrow Company" />
                        </div>
                        Past Prescriptions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/home/all-prescriptions" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                        <div>
                          <img src={Dot} alt="Imprimis RX A Harrow Company" />
                        </div>
                        Request a Refill
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/home/account" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                        <div>
                          <img src={Dot} alt="Imprimis RX A Harrow Company" />
                        </div>
                        MY ACCOUNT
                      </NavLink>
                    </li>
                  </ul>
                </Box>
              </Stack>
            </Box>
            <Box className="site-header-profile-side">
              <Stack gap={1} className="sidebar_alignment">
                {/* <Box className="user_welcome_name">
                  <Typography title={user?.firstName + ' ' + user?.lastName}>
                    Welcome, <span>{`${user?.firstName} ${user.middleName} ${user?.lastName}`.trim()}</span>
                  </Typography>
                </Box>
                <Box className="user_profile_head_pic" onClick={handleClick}>
                  <Box className="profile_pic_block" style={user?.getSmallImage ? { background: `url('${user?.getSmallImage}')` } : {}}></Box>
                </Box> */}
                <a href="tel:8582642082" className="call-us-link">
                  <Stack direction="row" alignItems="center">
                    <img src={callUsIcon} alt="Imprimis RX A Harrow Company" width={20} />
                    <Typography>{`(858) 264-2082`}</Typography>
                  </Stack>
                  <Typography className="call-us-text">Text us with any questions</Typography>
                </a>
                <Typography onClick={logoutHandler} className="signout_btn">
                  Sign Out
                  <IconButton aria-label="Example" className="logout-sidebar-btn">
                    <img src={logout} alt="Imprimis RX A Harrow Company" />
                  </IconButton>
                </Typography>
                <img src={hamburgerIcon} alt="Imprimis RX A Harrow Company" width="40" className="hamburder_icon_btn" onClick={handleClick} />
              </Stack>
            </Box>

            <Box className="swipeable-right-drawer user-profile-menu" style={{ display: open ? 'block' : 'none' }}>
              <Box maxWidth="400px" width="300px" height="135px" display="flex" flexDirection="column">
                <Box id="basic-menu">
                  <MenuItem>
                    <Stack direction="row" justifyContent="space-between" align-items="center" className="dashboard_dropdown" onClick={settingHandler}>
                      <Stack direction="row">Profile Settings</Stack>
                      <Stack direction="row">
                        <IconButton aria-label="Example" className="logout-sidebar-btn">
                          <img src={gear} alt="Imprimis RX A Harrow Company" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </MenuItem>
                  <MenuItem>
                    <Stack direction="row" justifyContent="space-between" align-items="center" className="dashboard_dropdown" onClick={logoutHandler}>
                      <Stack direction="row">Sign Out</Stack>
                      <Stack direction="row">
                        <IconButton aria-label="Example" className="logout-sidebar-btn">
                          <img src={logout} alt="Imprimis RX A Harrow Company" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </MenuItem>
                </Box>
                <div className="arrow_icon">
                  <img src={Uparrow} height={22} width={22} alt="logo" />
                </div>
              </Box>
            </Box>
          </Stack>
        </Container>
      </AppBar>

      {openSidebar && (
        <Box className="swipeable-right-drawer" overflow="clip">
          <SwipeableTemporaryDrawer open={openSidebar} onClose={handleClose} onOpen={() => {}}>
            <Box maxWidth="400px" width="300px" height="100%" className="sidebar-drawer-wrap" display="flex" flexDirection="column">
              <IconButton aria-label="Example" onClick={handleClose} className="close-sidebar-btn">
                <img src={chevronright} alt="Imprimis RX A Harrow Company" />
              </IconButton>
              <List className="sidebar-drawer-list">
                <Box className="addon_small_screen">
                  <ul>
                    <ListItem>
                      <ListItemButton className="sidebar_btn">
                        <NavLink to="/home/dashboard" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                          <ListItemText>Pending Prescriptions</ListItemText>
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton className="sidebar_btn">
                        <NavLink to="/home/prescriptions" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                          <ListItemText>Past Prescriptions</ListItemText>
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton className="sidebar_btn">
                        <NavLink to="/home/all-prescriptions" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                          <ListItemText>Request a Refill</ListItemText>
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton className="sidebar_btn">
                        <NavLink to="/home/account" className={({ isActive }) => (isActive ? 'active && navbar-link' : 'navbar-link ')}>
                          <ListItemText>My Account</ListItemText>
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                  </ul>
                </Box>
              </List>
              <Grid direction="column" container className="sidebar-profile-block">
                <Grid direction="column" className="sidebar-profile-details" flex="1">
                  <Stack direction="column" alignItems="end" justifyContent="flex-end">
                    <Box className="user_profile_head_pic">
                      <Box className="profile_pic_block" style={user?.getSmallImage ? { background: `url('${user?.getSmallImage}')` } : {}}></Box>
                    </Box>
                    <Box className="sidebar-profile-desc" textAlign="right">
                      <Typography variant="h5" component="h5">
                        {`${user?.firstName + ' ' + user.middleName + ' ' + user?.lastName}`.trim()}
                      </Typography>
                      <Typography>{user?.email}</Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid direction="row" display="flex" justifyContent="flex-end" className="sidebar-profile-logout">
                  <Typography onClick={logoutHandler} variant="h6" sx={{ width: 'auto', display: 'inline' }}>
                    Sign Out
                    <IconButton aria-label="Example" className="logout-sidebar-btn">
                      <img src={logout} alt="Imprimis RX A Harrow Company" />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </SwipeableTemporaryDrawer>
        </Box>
      )}
    </>
  );
};

export default Header;
