import { FormBuilder, Validators, ValidatorFn, AbstractControl } from 'react-reactive-form';
import { UPDATE_PATIENT_PROFILE } from '../../../constants/Endpoints';

import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { User } from '../../../models/User';
import { toast } from 'react-toastify';
import moment from 'moment';
import { formatMessage } from '../../../constants/util';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';

function validateDate(value: string) {
  const date = moment(value);

  if (!date.isValid()) {
    return { invalidDate: 'Invalid date' };
  }

  return null;
}

export const personalLoginForm = FormBuilder.group({
  username: ['', [Validators.required]],
  password: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]]
});

export const personalUserForm = FormBuilder.group({
  firstName: ['', [Validators.required]], // Validators.pattern(/^[a-zA-Z\s']*$/) to add special characters validations
  middleName: ['', []],
  lastName: ['', [Validators.required]],
  dateOfBirth: ['', [Validators.required, validateDate]],
  gender: ['', [Validators.required]],
  phone: ['', [Validators.required]],
  email: ['', [Validators.required]],
  notification_preference: ['', [Validators.required]],
  allergies: ['', [Validators.required]],
  representativeFirstName: ['', []],
  representativeLastName: ['', []],
  representativeContact: ['', []],
  eyeDoctor: ['', []]
  // automaticRefill: ['false', []]
});

export const allergiesForm = FormBuilder.group({
  allergies: ['', [Validators.required]]

  // automaticRefill: ['false', []]
});

function formatDateToYYYYMMDD(utcDate: Date | string) {
  if (typeof utcDate !== 'string') {
    const yyyy = utcDate.getFullYear();
    const mm = String(utcDate.getMonth() + 1).padStart(2, '0');
    const dd = String(utcDate.getDate()).padStart(2, '0');
    console.log('Formatted Date:::', `${yyyy}-${mm}-${dd}`);
    return `${yyyy}-${mm}-${dd}`;
  }
  return utcDate;
}

export const settingUpdateUserProfileHandler = async (event: React.SyntheticEvent, user: User) => {
  try {
    event.preventDefault();
    personalUserForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.notification_preference.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.representativeFirstName.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.representativeLastName.markAsTouched({ emitEvent: true, onlySelf: true });
    personalUserForm.controls.representativeContact.markAsTouched({ emitEvent: true, onlySelf: true });
    // personalUserForm.controls.automaticRefill.markAsTouched({ emitEvent: true, onlySelf: true });

    // console.log(personalUserForm?.controls?.firstName?.invalid);
    // console.log(personalUserForm?.controls?.middleName?.invalid);
    // console.log(personalUserForm?.controls?.lastName?.invalid);
    // console.log(personalUserForm?.controls?.dateOfBirth?.invalid);
    // console.log(personalUserForm?.controls?.gender?.invalid);
    // console.log(personalUserForm?.controls?.phone?.invalid);
    // console.log(personalUserForm?.controls?.email?.invalid);
    // console.log(personalUserForm?.controls?.notification_preference?.invalid);
    // console.log(personalUserForm?.controls?.allergies?.invalid);
    // console.log(personalUserForm?.controls?.representativeFirstName?.invalid);
    // console.log(personalUserForm?.controls?.representativeLastName?.invalid);
    // console.log(personalUserForm?.controls?.representativeContact?.invalid);
    // console.log(personalUserForm.invalid);

    if (personalUserForm.invalid) {
      return;
    }
    // console.log('personalUserForm', personalUserForm.value);

    const phoneNumberDigits = personalUserForm?.value?.phone?.replace(/\D/g, '');
    const area_code = phoneNumberDigits.slice(0, 3);
    const number = phoneNumberDigits.slice(3);

    console.log('DAE:::', personalUserForm?.value?.dateOfBirth);

    let data: any = {
      firstName: personalUserForm.value.firstName,
      middleName: personalUserForm.value.middleName ? personalUserForm.value.middleName : '',
      lastName: personalUserForm.value.lastName,
      dateOfBirth: formatDateToYYYYMMDD(personalUserForm?.value?.dateOfBirth),
      gender: personalUserForm.value.gender,
      email: personalUserForm.value.email,
      notificationPreference: personalUserForm.value.notification_preference,
      allergies: personalUserForm.value.allergies.split(',') || ['No Known Allergies'],
      phoneNumber: {
        areaCode: area_code,
        number: number
      },
      address: {
        // address1: user.address.address1,
        // state: user.address.state,
        // city: user.address.city,
        // zipCode: user.address.zipCode,
        // country: user.address.country
      },
      refreshToken: user?.refreshToken
      // autoRefill: ['', null, undefined, 'false', false].includes(personalUserForm?.value?.automaticRefill) ? false : true
    };

    const representativeContactDigits = personalUserForm?.value?.representativeContact.replace(/\D/g, '');

    if (representativeContactDigits && personalUserForm?.value?.representativeFirstName && personalUserForm?.value?.representativeLastName) {
      data.patientRepresentative = {
        firstName: personalUserForm?.value?.representativeFirstName,
        lastName: personalUserForm?.value?.representativeLastName,
        phoneNumber: {
          areaCode: representativeContactDigits ? representativeContactDigits.slice(0, 3) : '',
          number: representativeContactDigits ? representativeContactDigits.slice(3) : ''
        }
      };
    } else {
      data.patientRepresentative = null;
    }

    const res: AxiosResponse = await Patient.post(UPDATE_PATIENT_PROFILE, data);
    if (res.status === 201) {
      toast(formatMessage('Profile updated successfully'));
      return res;
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return false;
    }
  }
};

export const refillPreferenceForm = FormBuilder.group({
  firstName: ['', [Validators.required]],
  middleName: ['', []],
  lastName: ['', [Validators.required]],
  dateOfBirth: ['', [Validators.required, validateDate]],
  gender: ['', [Validators.required]],
  phone: ['', [Validators.required]],
  email: ['', [Validators.required]],
  notification_preference: ['', [Validators.required]],
  allergies: ['', [Validators.required]],
  representativeFirstName: ['', []],
  representativeLastName: ['', []],
  representativeContact: ['', []],
  eyeDoctor: ['', []]
  // automaticRefill: ['false', []]
});

export const settingUpdateRefillPreferenceHandler = async (event: React.SyntheticEvent, user: User) => {
  try {
    event.preventDefault();
    refillPreferenceForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.notification_preference.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.representativeFirstName.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.representativeLastName.markAsTouched({ emitEvent: true, onlySelf: true });
    refillPreferenceForm.controls.representativeContact.markAsTouched({ emitEvent: true, onlySelf: true });
    // refillPreferenceForm.controls.automaticRefill.markAsTouched({ emitEvent: true, onlySelf: true });

    // console.log(refillPreferenceForm?.controls?.firstName?.invalid);
    // console.log(refillPreferenceForm?.controls?.middleName?.invalid);
    // console.log(refillPreferenceForm?.controls?.lastName?.invalid);
    // console.log(refillPreferenceForm?.controls?.dateOfBirth?.invalid);
    // console.log(refillPreferenceForm?.controls?.gender?.invalid);
    // console.log(refillPreferenceForm?.controls?.phone?.invalid);
    // console.log(refillPreferenceForm?.controls?.email?.invalid);
    // console.log(refillPreferenceForm?.controls?.notification_preference?.invalid);
    // console.log(refillPreferenceForm?.controls?.allergies?.invalid);
    // console.log(refillPreferenceForm?.controls?.representativeFirstName?.invalid);
    // console.log(refillPreferenceForm?.controls?.representativeLastName?.invalid);
    // console.log(refillPreferenceForm?.controls?.representativeContact?.invalid);
    // console.log(refillPreferenceForm.invalid);

    if (refillPreferenceForm.invalid) {
      return;
    }
    // console.log('refillPreferenceForm', refillPreferenceForm.value);

    const phoneNumberDigits = refillPreferenceForm?.value?.phone?.replace(/\D/g, '');
    const area_code = phoneNumberDigits.slice(0, 3);
    const number = phoneNumberDigits.slice(3);

    let data: any = {
      firstName: refillPreferenceForm.value.firstName,
      middleName: refillPreferenceForm.value.middleName ? refillPreferenceForm.value.middleName : '',
      lastName: refillPreferenceForm.value.lastName,
      dateOfBirth: formatDateToYYYYMMDD(refillPreferenceForm?.value?.dateOfBirth),
      gender: refillPreferenceForm.value.gender,
      email: refillPreferenceForm.value.email,
      notificationPreference: refillPreferenceForm.value.notification_preference,
      allergies: refillPreferenceForm.value.allergies.split(',') || ['No Known Allergies'],
      phoneNumber: {
        areaCode: area_code,
        number: number
      },
      address: {
        // address1: user.address.address1,
        // state: user.address.state,
        // city: user.address.city,
        // zipCode: user.address.zipCode,
        // country: user.address.country
      },
      refreshToken: user?.refreshToken
      // autoRefill: ['', null, undefined, 'false', false].includes(refillPreferenceForm?.value?.automaticRefill) ? false : true
    };

    const representativeContactDigits = refillPreferenceForm?.value?.representativeContact.replace(/\D/g, '');

    if (representativeContactDigits && refillPreferenceForm?.value?.representativeFirstName && refillPreferenceForm?.value?.representativeLastName) {
      data.patientRepresentative = {
        firstName: refillPreferenceForm?.value?.representativeFirstName,
        lastName: refillPreferenceForm?.value?.representativeLastName,
        phoneNumber: {
          areaCode: representativeContactDigits ? representativeContactDigits.slice(0, 3) : '',
          number: representativeContactDigits ? representativeContactDigits.slice(3) : ''
        }
      };
    } else {
      data.patientRepresentative = null;
    }

    const res: AxiosResponse = await Patient.post(UPDATE_PATIENT_PROFILE, data);
    if (res.status === 201) {
      toast(formatMessage('Preference Updated Successfully'));
      return res;
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return false;
    }
  }
};

export const patientRepresentativeForm = FormBuilder.group({
  firstName: ['', [Validators.required]],
  middleName: ['', []],
  lastName: ['', [Validators.required]],
  dateOfBirth: ['', [Validators.required, validateDate]],
  gender: ['', [Validators.required]],
  phone: ['', [Validators.required]],
  email: ['', [Validators.required]],
  notification_preference: ['', [Validators.required]],
  allergies: ['', [Validators.required]],
  representativeFirstName: ['', []],
  representativeLastName: ['', []],
  representativeContact: ['', []],
  eyeDoctor: ['', []]
  // automaticRefill: ['false', []]
});

export const settingUpdatePatinetRepresentativeFormHandler = async (event: React.SyntheticEvent, user: User) => {
  try {
    event.preventDefault();
    patientRepresentativeForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.notification_preference.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.representativeFirstName.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.representativeLastName.markAsTouched({ emitEvent: true, onlySelf: true });
    patientRepresentativeForm.controls.representativeContact.markAsTouched({ emitEvent: true, onlySelf: true });
    // patientRepresentativeForm.controls.automaticRefill.markAsTouched({ emitEvent: true, onlySelf: true });

    // console.log(patientRepresentativeForm?.controls?.firstName?.invalid);
    // console.log(patientRepresentativeForm?.controls?.middleName?.invalid);
    // console.log(patientRepresentativeForm?.controls?.lastName?.invalid);
    // console.log(patientRepresentativeForm?.controls?.dateOfBirth?.invalid);
    // console.log(patientRepresentativeForm?.controls?.gender?.invalid);
    // console.log(patientRepresentativeForm?.controls?.phone?.invalid);
    // console.log(patientRepresentativeForm?.controls?.email?.invalid);
    // console.log(patientRepresentativeForm?.controls?.notification_preference?.invalid);
    // console.log(patientRepresentativeForm?.controls?.allergies?.invalid);
    // console.log(patientRepresentativeForm?.controls?.representativeFirstName?.invalid);
    // console.log(patientRepresentativeForm?.controls?.representativeLastName?.invalid);
    // console.log(patientRepresentativeForm?.controls?.representativeContact?.invalid);
    // console.log(patientRepresentativeForm.invalid);

    if (patientRepresentativeForm.invalid) {
      return;
    }
    // console.log('patientRepresentativeForm', patientRepresentativeForm.value);

    const phoneNumberDigits = patientRepresentativeForm?.value?.phone?.replace(/\D/g, '');
    const area_code = phoneNumberDigits.slice(0, 3);
    const number = phoneNumberDigits.slice(3);

    let data: any = {
      firstName: patientRepresentativeForm.value.firstName,
      middleName: patientRepresentativeForm.value.middleName ? patientRepresentativeForm.value.middleName : '',
      lastName: patientRepresentativeForm.value.lastName,
      dateOfBirth: formatDateToYYYYMMDD(patientRepresentativeForm?.value?.dateOfBirth),
      gender: patientRepresentativeForm.value.gender,
      email: patientRepresentativeForm.value.email,
      notificationPreference: patientRepresentativeForm.value.notification_preference,
      allergies: patientRepresentativeForm.value.allergies.split(',') || ['No Known Allergies'],
      phoneNumber: {
        areaCode: area_code,
        number: number
      },
      address: {
        // address1: user.address.address1,
        // state: user.address.state,
        // city: user.address.city,
        // zipCode: user.address.zipCode,
        // country: user.address.country
      },
      refreshToken: user?.refreshToken
      // autoRefill: ['', null, undefined, 'false', false].includes(patientRepresentativeForm?.value?.automaticRefill) ? false : true
    };

    const representativeContactDigits = patientRepresentativeForm?.value?.representativeContact.replace(/\D/g, '');

    if (representativeContactDigits && patientRepresentativeForm?.value?.representativeFirstName && patientRepresentativeForm?.value?.representativeLastName) {
      data.patientRepresentative = {
        firstName: patientRepresentativeForm?.value?.representativeFirstName,
        lastName: patientRepresentativeForm?.value?.representativeLastName,
        phoneNumber: {
          areaCode: representativeContactDigits ? representativeContactDigits.slice(0, 3) : '',
          number: representativeContactDigits ? representativeContactDigits.slice(3) : ''
        }
      };
    } else if (patientRepresentativeForm?.value?.representativeFirstName && patientRepresentativeForm?.value?.representativeLastName && !representativeContactDigits) {
      return;
    } else {
      data.patientRepresentative = null;
    }

    const res: AxiosResponse = await Patient.post(UPDATE_PATIENT_PROFILE, data);
    if (res.status === 201) {
      toast(formatMessage('Contact Updated Successfully'));
      return res;
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return false;
    }
  }
};

export const AllergiesUpdateHandler = async (event: React.SyntheticEvent, orderId: Number) => {
  try {
    event.preventDefault();

    allergiesForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });

    if (allergiesForm.invalid) {
      return;
    }

    let data: any = {
      allergies: allergiesForm.value.allergies.split(',') || ['No Known Allergies']
    };

    const res: AxiosResponse = await PatientWithoutToken.post(UPDATE_PATIENT_PROFILE, data);
    if (res.status === 201) {
      toast(formatMessage('Allergy updated successfully'));
      return res;
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return false;
    }
  }
};
