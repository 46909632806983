import { FormBuilder, Validators } from 'react-reactive-form';
import { REGISTER_PATIENT, GET_EXISTING_PATIENT, GUEST_LOGIN } from '../../constants/Endpoints';

import { AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import Patient from '../../constants/grx-api';
import { toast } from 'react-toastify';
import { User } from '../../models/User';
import moment from 'moment';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { formatMessage } from '../../constants/util';

function lengthValidator(control: any) {
  if (control && control.value) {
    const value = control.value.replaceAll('-', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('_', '');
    const minLength = 10;
    const maxLength = 10;

    if (value.length < minLength || value.length > maxLength) {
      return { length: true }; // Validation failed
    }

    return null; // Validation passed
  }
  return null;
}

function validateDate(value: string) {
  const date = moment(value);

  if (!date.isValid()) {
    return { invalidDate: 'Invalid date' };
  }

  return null;
}

function cardLengthValidator(control: any) {
  if (control && control.value) {
    const value = control.value.replaceAll('-', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('_', '');
    const minLength = 15;
    const maxLength = 16;

    if (value.length < minLength || value.length > maxLength) {
      return { length: true }; // Validation failed
    }

    return null; // Validation passed
  }
  return null;
}

export const PatientSignUpThreeForm = FormBuilder.group({
  firstName: ['', [Validators.required]], // Validators.pattern(/^[a-zA-Z\s']*$/) for removing special characters
  middleName: ['', []],
  lastName: ['', [Validators.required]],
  gender: ['', [Validators.required]],
  dateOfBirth: ['', [Validators.required, validateDate]],
  phone: ['', [Validators.required, lengthValidator]],
  email: ['', [Validators.required, Validators.email]],
  street: ['', [Validators.required]],
  city: ['', [Validators.required]],
  state: ['', [Validators.required]],
  zip_code: ['', [Validators.required]],


});

export const PatientSignUpOneForm = FormBuilder.group({
  firstName: ['', [Validators.required]], // Validators.pattern(/^[a-zA-Z\s']*$/) for removing special characters
  middleName: ['', []],
  lastName: ['', [Validators.required]],
  gender: ['', [Validators.required]],
  dateOfBirth: ['', [Validators.required, validateDate]],
  phone: ['', [Validators.required, lengthValidator]],
  email: ['', [Validators.required, Validators.email]],
  password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/)]],
  notification_preference: ['', [Validators.required]],
  shippingZipCode: ['', [Validators.required]],
  isAllergies: ['true'],
  allergies: ['', []],
  representativeFirstName: ['', []],
  representativeLastName: ['', []],
  representativeContact: ['', []],
  eyeDoctor: ['', []],
  // automaticRefill: ['', []],
  updateConsent: [true, []],
  promotionConsent: [true, []]
});

function formatDateToYYYYMMDD(utcDate: Date) {
  const yyyy = utcDate.getFullYear();
  const mm = String(utcDate.getMonth() + 1).padStart(2, '0');
  const dd = String(utcDate.getDate()).padStart(2, '0');
  console.log('Formatted Date:::', `${yyyy}-${mm}-${dd}`);
  return `${yyyy}-${mm}-${dd}`;
}

export const PatientSignUpOneHandler = async (router: NavigateFunction) => {
  try {
    PatientSignUpOneForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.password.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.notification_preference.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.shippingZipCode.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.representativeFirstName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.representativeLastName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.representativeContact.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpOneForm.controls.eyeDoctor.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpOneForm.controls.automaticRefill.markAsTouched({ emitEvent: true, onlySelf: true });

    console.log(PatientSignUpOneForm.value);

    if (PatientSignUpOneForm.controls.isAllergies.value === 'true' && PatientSignUpOneForm.controls.allergies.invalid) {
      return;
    }

    if (
      PatientSignUpOneForm.controls.firstName.invalid ||
      PatientSignUpOneForm.controls.lastName.invalid ||
      PatientSignUpOneForm.controls.gender.invalid ||
      PatientSignUpOneForm.controls.dateOfBirth.invalid ||
      PatientSignUpOneForm.controls.phone.invalid ||
      PatientSignUpOneForm.controls.email.invalid ||
      PatientSignUpOneForm.controls.password.invalid ||
      PatientSignUpOneForm.controls.shippingZipCode.invalid ||
      PatientSignUpOneForm.controls.notification_preference.invalid ||
      PatientSignUpOneForm.controls.allergies.invalid ||
      PatientSignUpOneForm.controls.representativeFirstName.invalid ||
      PatientSignUpOneForm.controls.representativeLastName.invalid ||
      PatientSignUpOneForm.controls.representativeContact.invalid
    ) {
      return;
    }

    const phoneNumberDigits = PatientSignUpOneForm?.value?.phone?.replace(/\D/g, '');
    const areaCode = phoneNumberDigits.slice(0, 3);
    const phoneNumber = phoneNumberDigits.slice(3);

    let data = {
      firstName: PatientSignUpOneForm.value.firstName,
      lastName: PatientSignUpOneForm.value.lastName,
      gender: PatientSignUpOneForm.value.gender,
      dateOfBirth: formatDateToYYYYMMDD(PatientSignUpOneForm.value.dateOfBirth),
      phoneNumber: {
        areaCode: areaCode ?? areaCode,
        number: phoneNumber ?? phoneNumber
      },
      email: PatientSignUpOneForm.value.email,
      shippingZipCode: PatientSignUpOneForm.value.shippingZipCode
    };

    const res: AxiosResponse = await PatientWithoutToken.post(GET_EXISTING_PATIENT, data);
    if (res.status === 201 || res.status === 200) {
      let allergies = PatientSignUpOneForm.value.isAllergies === 'true' && PatientSignUpOneForm.value.allergies ? Array.from(new Set([...PatientSignUpOneForm.value.allergies.split(','), ...res?.data?.allergies])).join(',') : Array.from(new Set([...res?.data?.allergies])).join(',');

      if (allergies.length === 0) {
        allergies = '';
      }

      let eyeDoctor = '';
      if (res?.data?.eyeDoctor) {
        eyeDoctor = `${res?.data?.eyeDoctor}`.trim();
      } else {
        eyeDoctor = PatientSignUpOneForm.value.eyeDoctor;
      }

      let patientData = {
        ...res.data,
        firstName: PatientSignUpOneForm.value.firstName,
        middleName: PatientSignUpOneForm.value.middleName,
        lastName: PatientSignUpOneForm.value.lastName,
        gender: PatientSignUpOneForm.value.gender,
        dateOfBirth: PatientSignUpOneForm.value.dateOfBirth,
        phone: PatientSignUpOneForm.value.phone,
        email: PatientSignUpOneForm.value.email,
        password: PatientSignUpOneForm.value.password,
        notification_preference: PatientSignUpOneForm.value.notification_preference,
        isAllergies: allergies.length > 0 ? 'true' : 'false',
        allergies: allergies,
        shippingZipCode: PatientSignUpOneForm.value.shippingZipCode,
        representativeFirstName: PatientSignUpOneForm.value.representativeFirstName,
        representativeLastName: PatientSignUpOneForm.value.representativeLastName,
        representativeContact: PatientSignUpOneForm.value.representativeContact,
        eyeDoctor: eyeDoctor,
        // automaticRefill: PatientSignUpOneForm.value.automaticRefill,
        found: true,
        updateConsent: true,
        promotionConsent: true
      };
      localStorage.clear();
      localStorage.setItem('registrationForm1', JSON.stringify(PatientSignUpOneForm.value));
      router('/signup-step-two', { state: { data: patientData } });
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      let allergies = PatientSignUpOneForm.value.isAllergies === 'true' && PatientSignUpOneForm.value.allergies ? Array.from(new Set([...PatientSignUpOneForm.value.allergies.split(',')])).join(',') : '';

      let patientData = {
        firstName: PatientSignUpOneForm.value.firstName,
        middleName: PatientSignUpOneForm.value.middleName,
        lastName: PatientSignUpOneForm.value.lastName,
        gender: PatientSignUpOneForm.value.gender,
        dateOfBirth: PatientSignUpOneForm.value.dateOfBirth,
        phone: PatientSignUpOneForm.value.phone,
        email: PatientSignUpOneForm.value.email,
        password: PatientSignUpOneForm.value.password,
        notification_preference: PatientSignUpOneForm.value.notification_preference,
        isAllergies: allergies.length > 0 ? 'true' : 'false',
        allergies: allergies,
        shippingZipCode: PatientSignUpOneForm.value.shippingZipCode,
        representativeFirstName: PatientSignUpOneForm.value.representativeFirstName,
        representativeLastName: PatientSignUpOneForm.value.representativeLastName,
        representativeContact: PatientSignUpOneForm.value.representativeContact,
        eyeDoctor: PatientSignUpOneForm.value.eyeDoctor,
        // automaticRefill: PatientSignUpOneForm.value.automaticRefill,
        found: false,
        updateConsent: true,
        promotionConsent: true
      };
      localStorage.clear();
      localStorage.setItem('registrationForm1', JSON.stringify(PatientSignUpOneForm.value));
      router('/signup-step-two', { state: { data: patientData } });
      return;
    }
  }
};

export const PatientSignUpTwoForm = FormBuilder.group({
  firstName: ['', [Validators.required]],
  middleName: [''],
  lastName: ['', [Validators.required]],
  gender: ['', [Validators.required]],
  dateOfBirth: ['', [Validators.required, validateDate]],
  phone: ['', [Validators.required, lengthValidator]],
  email: ['', [Validators.required, Validators.email]],
  password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/)]],
  notification_preference: ['', [Validators.required]],
  isAllergies: ['true'],
  allergies: ['', []],
  // cardNumber: ['', [Validators.required, cardLengthValidator]],
  // expiry: ['', [Validators.required]],
  // cvv: ['', [Validators.required]],
  // cardholderName: ['', [Validators.required]],
  // card_street: ['', [Validators.required]],
  // card_apt_suite: ['', []],
  // card_zip_code: ['', [Validators.required]],
  // card_city: ['', [Validators.required]],
  // card_state: ['', [Validators.required]],
  // billing_shipping_same: [false, []],
  street: ['', [Validators.required]],
  apt_suite: ['', []],
  city: ['', [Validators.required]],
  state: ['', [Validators.required]],
  zip_code: ['', [Validators.required]],
  representativeFirstName: ['', []],
  representativeLastName: ['', []],
  representativeContact: ['', []],
  eyeDoctor: ['', []],
  // automaticRefill: ['', []],
  updateConsent: [true, []],
  promotionConsent: [true, []]
});

export const PatientSignUpTwoHandler = async (router: NavigateFunction) => {
  try {
    PatientSignUpTwoForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.password.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.notification_preference.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.allergies.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.cardNumber.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.expiry.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.cvv.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.cardholderName.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.card_street.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.card_apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.card_zip_code.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.card_city.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.card_state.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.billing_shipping_same.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.zip_code.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.representativeFirstName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.representativeLastName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.representativeContact.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.eyeDoctor.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpTwoForm.controls.automaticRefill.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.updateConsent.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpTwoForm.controls.promotionConsent.markAsTouched({ emitEvent: true, onlySelf: true });

    if (PatientSignUpTwoForm.controls.isAllergies.value === 'true' && PatientSignUpTwoForm.controls.allergies.invalid) {
      return;
    }

    if (
      PatientSignUpTwoForm.controls.firstName.invalid ||
      PatientSignUpTwoForm.controls.lastName.invalid ||
      PatientSignUpTwoForm.controls.gender.invalid ||
      PatientSignUpTwoForm.controls.dateOfBirth.invalid ||
      PatientSignUpTwoForm.controls.phone.invalid ||
      PatientSignUpTwoForm.controls.email.invalid ||
      PatientSignUpTwoForm.controls.password.invalid ||
      PatientSignUpTwoForm.controls.notification_preference.invalid ||
      PatientSignUpTwoForm.controls.allergies.invalid ||
      // PatientSignUpTwoForm.controls.cardNumber.invalid ||
      // PatientSignUpTwoForm.controls.expiry.invalid ||
      // PatientSignUpTwoForm.controls.cvv.invalid ||
      // PatientSignUpTwoForm.controls.cardholderName.invalid ||
      // PatientSignUpTwoForm.controls.card_street.invalid ||
      // PatientSignUpTwoForm.controls.card_apt_suite.invalid ||
      // PatientSignUpTwoForm.controls.card_zip_code.invalid ||
      // PatientSignUpTwoForm.controls.card_city.invalid ||
      // PatientSignUpTwoForm.controls.card_state.invalid ||
      // PatientSignUpTwoForm.controls.billing_shipping_same.invalid ||
      PatientSignUpTwoForm.controls.street.invalid ||
      PatientSignUpTwoForm.controls.apt_suite.invalid ||
      PatientSignUpTwoForm.controls.city.invalid ||
      PatientSignUpTwoForm.controls.state.invalid ||
      PatientSignUpTwoForm.controls.zip_code.invalid ||
      PatientSignUpTwoForm.controls.updateConsent.invalid ||
      PatientSignUpTwoForm.controls.promotionConsent.invalid
    ) {
      return;
    }

    const phoneNumberDigits = PatientSignUpTwoForm.value.phone.replace(/\D/g, '');
    const areaCode = phoneNumberDigits.slice(0, 3);
    const phoneNumber = phoneNumberDigits.slice(3);

    let data = {
      firstName: PatientSignUpTwoForm?.value?.firstName,
      middleName: PatientSignUpTwoForm?.value?.middleName,
      lastName: PatientSignUpTwoForm?.value?.lastName,
      gender: PatientSignUpTwoForm?.value?.gender,
      dateOfBirth: formatDateToYYYYMMDD(PatientSignUpTwoForm?.value?.dateOfBirth),
      phoneNumber: {
        areaCode: areaCode,
        number: phoneNumber
      },
      email: PatientSignUpTwoForm?.value?.email,
      notificationPreference: PatientSignUpTwoForm?.value?.notification_preference,
      allergies: PatientSignUpTwoForm?.value?.allergies !== '' ? PatientSignUpTwoForm?.value?.allergies?.split(',') : ['none'],
      password: PatientSignUpTwoForm?.value?.password,
      // billingInfo: {
      //   number: PatientSignUpTwoForm?.value?.cardNumber?.split(' ')?.join('')?.replaceAll('_', ''),
      //   expirationMonth: PatientSignUpTwoForm?.value?.expiry?.split('/')[0],
      //   expirationYear: PatientSignUpTwoForm?.value?.expiry?.split('/')[1],
      //   cvc: PatientSignUpTwoForm?.value?.cvv,
      //   zip_code: PatientSignUpTwoForm?.value?.zip_code,
      //   billingAddress: {
      //     city: PatientSignUpTwoForm?.value?.card_city,
      //     state: PatientSignUpTwoForm?.value?.card_state,
      //     zipCode: PatientSignUpTwoForm?.value?.card_zip_code,
      //     address1: PatientSignUpTwoForm?.value?.card_street,
      //     country: 'US'
      //   }
      // },
      // shippingAddress: {
      //   city: PatientSignUpTwoForm?.value?.billing_shipping_same ? PatientSignUpTwoForm?.value?.card_city : PatientSignUpTwoForm?.value?.city,
      //   state: PatientSignUpTwoForm?.value?.billing_shipping_same ? PatientSignUpTwoForm?.value?.card_state : PatientSignUpTwoForm?.value?.state,
      //   zipCode: PatientSignUpTwoForm?.value?.billing_shipping_same ? PatientSignUpTwoForm?.value?.card_zip_code : PatientSignUpTwoForm?.value?.zip_code,
      //   address1: PatientSignUpTwoForm?.value?.billing_shipping_same ? PatientSignUpTwoForm?.value?.card_street : PatientSignUpTwoForm?.value?.street,
      //   country: 'US'
      // },
      shippingAddress: {
        city: PatientSignUpTwoForm?.value?.city,
        state: PatientSignUpTwoForm?.value?.state,
        zipCode: PatientSignUpTwoForm?.value?.zip_code,
        address1: PatientSignUpTwoForm?.value?.street,
        country: 'US'
      },
      patientRepresentative: PatientSignUpTwoForm?.value?.representativeContact
        ? {
          firstName: PatientSignUpTwoForm?.value?.representativeFirstName ? PatientSignUpTwoForm?.value?.representativeFirstName : '',
          lastName: PatientSignUpTwoForm?.value?.representativeLastName ? PatientSignUpTwoForm?.value?.representativeLastName : '',
          phoneNumber: {
            areaCode: PatientSignUpTwoForm?.value?.representativeContact.replace(/\D/g, '').slice(0, 3),
            number: PatientSignUpTwoForm?.value?.representativeContact.replace(/\D/g, '').slice(3)
          }
        }
        : null,
      eyeDoctor: PatientSignUpTwoForm?.value?.eyeDoctor,
      // autoRefill: ['', null, undefined].includes(PatientSignUpTwoForm?.value?.automaticRefill) ? false : PatientSignUpTwoForm?.value?.automaticRefill,
      updateConsent: PatientSignUpTwoForm?.value?.updateConsent,
      promotionConsent: PatientSignUpTwoForm?.value?.promotionConsent
    };

    const res: AxiosResponse = await PatientWithoutToken.post(REGISTER_PATIENT, data);
    if (res.status === 201) {
      localStorage.clear();
      sessionStorage.setItem('isSessionOngoing', 'true');
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('idToken', res?.data?.idToken);
      localStorage.setItem('accessToken', res?.data?.accesshToken);
      localStorage.setItem('refreshToken', res?.data?.refreshToken);
      localStorage.setItem('billingPopup', '0');
      toast(formatMessage('Registered Successfully'));
      router('/home/dashboard');
      PatientSignUpTwoForm.reset();
      return User.create(res?.data, true);
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

// export const GuestCheckOutTwoHandler = async (router: NavigateFunction) => {
//   try {
//     PatientSignUpOneForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
//     PatientSignUpOneForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
//     PatientSignUpOneForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
//     PatientSignUpOneForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
//     PatientSignUpOneForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
//     PatientSignUpOneForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
//     PatientSignUpOneForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
//      PatientSignUpOneForm.controls.shippingZipCode.markAsTouched({ emitEvent: true, onlySelf: true });

//     // PatientSignUpOneForm.controls.automaticRefill.markAsTouched({ emitEvent: true, onlySelf: true });

//     console.log(PatientSignUpOneForm.value);

//     if (PatientSignUpOneForm.controls.isAllergies.value === 'true' && PatientSignUpOneForm.controls.allergies.invalid) {
//       return;
//     }

//     if (
//       PatientSignUpOneForm.controls.firstName.invalid ||
//       PatientSignUpOneForm.controls.lastName.invalid ||
//       PatientSignUpOneForm.controls.gender.invalid ||
//       PatientSignUpOneForm.controls.dateOfBirth.invalid ||
//       PatientSignUpOneForm.controls.phone.invalid ||
//       PatientSignUpOneForm.controls.email.invalid ||
//       PatientSignUpOneForm.controls.shippingZipCode.invalid

//     ) {
//       return;
//     }

//     const phoneNumberDigits = PatientSignUpOneForm?.value?.phone?.replace(/\D/g, '');
//     const areaCode = phoneNumberDigits.slice(0, 3);
//     const phoneNumber = phoneNumberDigits.slice(3);

//     let data = {
//       firstName: PatientSignUpOneForm.value.firstName,
//       lastName: PatientSignUpOneForm.value.lastName,
//       gender: PatientSignUpOneForm.value.gender,
//       dateOfBirth: formatDateToYYYYMMDD(PatientSignUpOneForm.value.dateOfBirth),
//       phoneNumber: {
//         areaCode: areaCode ?? areaCode,
//         number: phoneNumber ?? phoneNumber
//       },
//       email: PatientSignUpOneForm.value.email,
//       shippingZipCode: PatientSignUpOneForm.value.shippingZipCode
//     };

//     const res: AxiosResponse = await PatientWithoutToken.post(GET_EXISTING_PATIENT, data);
//     if (res.status === 201 || res.status === 200) {
//       let allergies = PatientSignUpOneForm.value.isAllergies === 'true' && PatientSignUpOneForm.value.allergies ? Array.from(new Set([...PatientSignUpOneForm.value.allergies.split(','), ...res?.data?.allergies])).join(',') : Array.from(new Set([...res?.data?.allergies])).join(',');

//       if (allergies.length === 0) {
//         allergies = '';
//       }

//       let eyeDoctor = '';
//       if (res?.data?.eyeDoctor) {
//         eyeDoctor = `${res?.data?.eyeDoctor}`.trim();
//       } else {
//         eyeDoctor = PatientSignUpOneForm.value.eyeDoctor;
//       }

//       let patientData = {
//         ...res.data,
//         firstName: PatientSignUpOneForm.value.firstName,
//         middleName: PatientSignUpOneForm.value.middleName,
//         lastName: PatientSignUpOneForm.value.lastName,
//         gender: PatientSignUpOneForm.value.gender,
//         dateOfBirth: PatientSignUpOneForm.value.dateOfBirth,
//         phone: PatientSignUpOneForm.value.phone,
//         email: PatientSignUpOneForm.value.email,

//         shippingZipCode: PatientSignUpOneForm.value.shippingZipCode,


//       };
//       localStorage.clear();
//       localStorage.setItem('registrationForm1', JSON.stringify(PatientSignUpOneForm.value));
//       router('/pending-prescription', { state: { data: patientData } });
//     }
//   } catch (err: any) {
//     if (err?.response?.data?.message) {
//       let allergies = PatientSignUpOneForm.value.isAllergies === 'true' && PatientSignUpOneForm.value.allergies ? Array.from(new Set([...PatientSignUpOneForm.value.allergies.split(',')])).join(',') : '';

//       let patientData = {
//         firstName: PatientSignUpOneForm.value.firstName,
//         middleName: PatientSignUpOneForm.value.middleName,
//         lastName: PatientSignUpOneForm.value.lastName,
//         gender: PatientSignUpOneForm.value.gender,
//         dateOfBirth: PatientSignUpOneForm.value.dateOfBirth,
//         phone: PatientSignUpOneForm.value.phone,
//         email: PatientSignUpOneForm.value.email,
//          allergies: allergies,
//         shippingZipCode: PatientSignUpOneForm.value.shippingZipCode,


//       };
//       localStorage.clear();
//       localStorage.setItem('registrationForm1', JSON.stringify(PatientSignUpOneForm.value));
//       router('/signup-step-two', { state: { data: patientData } });
//       return;
//     }
//   }
// };

export const GuestCheckOutTwoHandler = async (router: NavigateFunction, handleMessage: (res?: boolean) => void
) => {

  try {
    PatientSignUpThreeForm.controls.firstName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.middleName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.lastName.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.gender.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.dateOfBirth.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.phone.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpThreeForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpThreeForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpThreeForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    // PatientSignUpThreeForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    PatientSignUpThreeForm.controls.zip_code.markAsTouched({ emitEvent: true, onlySelf: true });




    if (
      PatientSignUpThreeForm.controls.firstName.invalid ||
      PatientSignUpThreeForm.controls.lastName.invalid ||
      PatientSignUpThreeForm.controls.gender.invalid ||
      PatientSignUpThreeForm.controls.dateOfBirth.invalid ||
      PatientSignUpThreeForm.controls.phone.invalid ||
      PatientSignUpThreeForm.controls.email.invalid ||
      // PatientSignUpThreeForm.controls.street.invalid ||
      // PatientSignUpThreeForm.controls.city.invalid ||
      //  PatientSignUpThreeForm.controls.state.invalid ||
      PatientSignUpThreeForm.controls.zip_code.invalid
    ) {


      return;
    }

    const phoneNumberDigits = PatientSignUpThreeForm.value.phone.replace(/\D/g, '');
    const areaCode = phoneNumberDigits.slice(0, 3);
    const phoneNumber = phoneNumberDigits.slice(3);

    let data = {

      firstName: PatientSignUpThreeForm.value.firstName,
      lastName: PatientSignUpThreeForm.value.lastName,
      gender: PatientSignUpThreeForm.value.gender,
      dateOfBirth: formatDateToYYYYMMDD(PatientSignUpThreeForm.value.dateOfBirth),
      phoneNumber: {
        areaCode: areaCode ?? areaCode,
        number: phoneNumber ?? phoneNumber
      },
      email: PatientSignUpThreeForm.value.email,
      shippingAddress: {
        // city: PatientSignUpThreeForm?.value?.city,
        // state: PatientSignUpThreeForm?.value?.state,
        zipCode: PatientSignUpThreeForm?.value?.zip_code,
        // address1: PatientSignUpThreeForm?.value?.street,
        // country: 'US'
      }


    };

    const res: AxiosResponse = await PatientWithoutToken.post(GUEST_LOGIN, data);
    if (res.status === 201) {
      localStorage.clear();
      if (res?.data.orders[0] == null) {
        toast.success('We found no prescription ready for the payment for you');
      } else {
        // sessionStorage.setItem('isSessionOngoing', 'true');
        // localStorage.setItem('isLoggedIn', 'true');
        // localStorage.setItem('idToken', res?.data?.idToken);
        // localStorage.setItem('accessToken', res?.data?.accesshToken);
        // localStorage.setItem('refreshToken', res?.data?.refreshToken);
        // localStorage.setItem('billingPopup', '0');
        localStorage.setItem('Orders', JSON.stringify(res?.data));
        localStorage.setItem('tab', "1");

        localStorage.setItem('guestEmail', JSON.stringify(PatientSignUpThreeForm.value.email));

        toast(formatMessage('login Successfully'));
        // router('/home/pending-prescription');
        // PatientSignUpThreeForm.reset();
        return res.status;
      }

    }
  } catch (err: any) {
    console.log("hii23", err)


    if (err?.response?.data?.message) {
      // toast(formatMessage(err?.response.data.message));
      if (err?.response.data.message.toLowerCase() == "patient information not found") {
        toast(formatMessage(err?.response.data.message));
      } else {
        handleMessage(true)
      }
      return;
    }
  }
};
