import '../theme/style.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import { useEffect, useState } from 'react';

import DashboardPage from './Home/dashboard';

// import DonePage from './Auth/forgot-password/reset-password/done';
import MailConfirm from './Auth/forgot-password/done';
import ForgotPassword from './Auth/forgot-password';
// import { LoginPage } from './Auth/Login';
import OrderPage from './Home/prescriptions/order';
import PrescriptionPage from './Home/prescriptions';
import React from 'react';
import ResetPasswordPage from './Auth/forgot-password/reset-password';
import SettingPage from './Home/settings';
import SignUpPageStepOne from './Auth/signup/signupPageStepOne';
import SignUpPageStepTwo from './Auth/signup/signupPageStepTwo';
import { ToastContainer } from 'react-toastify';
import { User } from '../models/User';
import { isLoggedIn } from '../services/auth/auth.service';
import { profileActions } from '../store/Actions';
import { useNavigate } from 'react-router-dom';
import PrivacyPolicy from './Home/privacyPolicy';
import { datadogRum } from '@datadog/browser-rum';
import LoginPage from './Auth/login-page/index';
import ConfirmOtp from './Auth/confirm-otp';
import VerifyPhone from './Auth/verify-details/verifyPhone';
import VerifyEmail from './Auth/verify-details/verifyEmail';
import EmailLinkLogin from './Auth/email-login';
import GuestCheckout from './Auth/signup/guest-checkout';
import AllPrescriptionPage from './Home/request-refill';

// env variable
const { REACT_APP_ENV_NAME } = process.env;
const isProd = REACT_APP_ENV_NAME === 'production';

function App() {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();

  const isLogin = useAppSelector((state) => state?.profileReducer?.isLogin);
  const user: User = useAppSelector((state) => state?.profileReducer?.user);

  const datadogInitialization = () => {
    const appId: any = process.env.REACT_APP_DATADOG_APP_ID;
    const clientToken: any = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
    if (appId && clientToken) {
      datadogRum.init({
        applicationId: appId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: 'prescriber-portal',
        env: 'Prod',
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
      datadogRum.startSessionReplayRecording();
    }
  };

  const init = () => {
    if (localStorage) {
      dispatch(profileActions.setLogin(isLoggedIn()));
    } else {
      setTimeout(() => {
        init();
      }, 100);
    }
  };

  useEffect(() => {
    init();
  });

  useEffect(() => {
    if (isProd) {
      datadogInitialization();
    }
  }, []);

  useEffect(() => {
    if (isProd) {
      const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
      if (isLogin) {
        // if user is logged in
        window.Intercom('boot', {
          app_id: intercomAppId,
          user_id: user?.id,
          email: user?.email,
          name: `${user?.firstName + user?.lastName}`
        });
      } else {
        // if user log out
        window.Intercom('shutdown');
        window.Intercom('boot', {
          app_id: intercomAppId,
          custom_launcher_selector: '#custom-launcher'
        });
      }
    }
  }, [user, isLogin]);

  useEffect(() => {
    if (isLogin) {
      try {
        const localUser = localStorage.getItem('User');
        if (localUser) {
          const user: User = User.create(JSON.parse(localUser)) as User;
          dispatch(profileActions.setUserData({ user }));
        } else {
          localStorage.clear();
          sessionStorage.clear();
          dispatch(profileActions.setLogin(false));
        }

        setIsLoaded(true);
      } catch (err) {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(profileActions.setLogin(false));
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
  }, [isLogin, dispatch]);

  const AuthRoutes = () => {
    const navigate = useNavigate();

    useEffect(() => {
      if (isLogin) {
        navigate('/home');
      }
    });
    return <React.Fragment>{!isLogin && <Outlet />}</React.Fragment>;
  };


  useEffect(() => {
    // Check if the previous location was '/guest-checkout'
    if (location.pathname !== '/guest-checkout' && localStorage.getItem('tab')) {
      localStorage.clear(); // Clear the specific key
    }
  }, [location]);

  const HomeRoutes = () => {
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLogin) {
        navigate('/');
      }
    });
    return <React.Fragment>{isLogin && <Outlet />}</React.Fragment>;
  };

  const HomeRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if (user) navigate('/home/dashboard');
    });

    return <></>;
  };

  return isLoaded ? (
    <>
      <Routes>
        <Route path="/" element={<AuthRoutes />}>
          <Route path="" element={<LoginPage />} />
          <Route path="/auth-zero/*" element={<EmailLinkLogin />} />
          {/* <Route path="send-otp" element={<SendOtp />} /> */}
          <Route path="confirm-otp" element={<ConfirmOtp />} />

          <Route path="signup-step-one" element={<SignUpPageStepOne />} />
          <Route path="signup-step-two" element={<SignUpPageStepTwo />} />
          <Route path="guest-checkout" element={<GuestCheckout />} />

          <Route path="verify-phone" element={<VerifyPhone />} />
          <Route path="verify-email" element={<VerifyEmail />} />

          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password/done" element={<MailConfirm />} />

          {/* <Route path="forgot-password/done" element={<DonePage />} /> */}
        </Route>

        <Route path="/home" element={<HomeRoutes />}>
          <Route path="" element={<HomeRedirect />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="prescriptions" element={<PrescriptionPage />} />
          <Route path="all-prescriptions" element={<AllPrescriptionPage />} />
          {user && (
            <Route>
              <Route path="prescriptions/order/:id" element={<OrderPage />} />
            </Route>
          )}
          <Route path="account" element={<SettingPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  ) : (
    <div></div>
  );
}

export default App;
