import { Box, Button, Paper, Stack } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { BootstrapDialog, StyledTableCell, StyledTableRow } from '../../core/tables/tableStyles';

import { SortConfigInterface } from '../../interfaces/sortConfig';
import Table from '@mui/material/Table';
import TableArrow from '../../assets/icons/table_arrow.svg';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { GET_SHIPPING_CARRIER, FEDEX_TRACK_URL, STAMPS_COM_TRACK_URL, UPS_TRACK_URL, PAY_INVOICE } from '../../constants/Endpoints';
import { AxiosResponse } from 'axios';
import Patient from '../../constants/grx-api';
import { toast } from 'react-toastify';
import { Order } from '../../models/Order';
import { OrderInterface } from '../../interfaces/order';
import { formatMessage } from '../../constants/util';
import { AutoEnrollRefill } from '../popup/EnrollInAutoRefill';
import { EditOrderShippingAddress } from '../popup/EditOrderShippingAddress';
import { RequestRefill } from '../popup/RequestRefill';
import { RequestRefillDetail } from '../popup/RequestRefillDetail';
import { AddNewCard } from '../popup/AddNewCardExtra';
import { MessagePopup } from '../popup/MessagePopup';
import { OrderDeliveryDate } from '../popup/AddExpectedDeliveryDate';
import PayInfo from '../popup/DeliveryPaymentInfo';
import { addNewShippingAddressForm } from '../../services/pages/popup-form/AddNewShippingAddressForm.service';

// const useSortableData = (items: OrderInterface[] = [], config = null) => {
//   const [sortConfig, setSortConfig]: [SortConfigInterface | null, Dispatch<SortConfigInterface | null>] = React.useState<SortConfigInterface | null>(config);

//   const sortedItems = React.useMemo(() => {
//     let sortableItems = [...items];
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key as keyof OrderInterface] < b[sortConfig.key as keyof OrderInterface]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1;
//         }
//         if (a[sortConfig.key as keyof OrderInterface] > b[sortConfig.key as keyof OrderInterface]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sortableItems;
//   }, [items, sortConfig]);

//   const requestSort = (key: string) => {
//     let direction = 'ascending';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };

//   return { items: sortedItems, requestSort, sortConfig };
// };

const useSortableData = (items: OrderInterface[] = [], config = null) => {
  const [sortConfig, setSortConfig]: [SortConfigInterface | null, Dispatch<SortConfigInterface | null>] = React.useState<SortConfigInterface | null>(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const keyParts = sortConfig.key.split('.');

        // Traverse nested properties
        const aValue = keyParts.reduce((obj, key) => obj && obj[key], a);
        const bValue = keyParts.reduce((obj, key) => obj && obj[key], b);

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

interface ShippingAddress {
  address1: any;
  address2: any;
  city: any;
  state: any;
  zipCode: any;
}

export const PrescriptionHistoryTable = (props: { data: OrderInterface[]; getDeliveredOrders: () => void }) => {
  const router = useNavigate();
  const formatDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY');
  };
  const [model, setModel] = useState<{ editAddress: boolean; addCard: boolean; requestRefill: boolean; autoRefill: boolean; message: boolean; orderDate: boolean; refillAddress: boolean; payInfo: boolean }>({
    editAddress: false,
    addCard: false,
    requestRefill: false,
    autoRefill: false,
    message: false,
    orderDate: false,
    refillAddress: false,
    payInfo: false
  });

  const [orderId, setOrderID] = useState<number>();
  const [unEnrolledIds, setUnEnrolledIds] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<String>('');
  const { items, requestSort, sortConfig } = useSortableData(props.data ?? []);
  const [rxId, setRxId] = useState<number[]>([]);

  const [originalShippingAddress, setOriginalShippingAddress] = useState<ShippingAddress>({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: ''
  });
  const setShippingAddress = (order: Order) => {
    addNewShippingAddressForm.patchValue({
      street: order.shippingAddress.address1,
      apt_suite: order.shippingAddress.address2,
      city: order.shippingAddress.city,
      state: order.shippingAddress.state,
      code: order.shippingAddress.zipCode,
      address: order.shippingAddress.address1
    });
  };
  const closeModel = () => {
    setModel((prev) => ({ ...prev, autoRefill: false, requestRefill: false }));
  };
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const openTrackingTab = (url: String, id: String) => {
    window.open(`${url}${id}`, '_blank');
  };

  const handleTracking = async (e: React.SyntheticEvent, orderId: number, trackingId: String) => {
    const carrierRes: AxiosResponse = await Patient.get(GET_SHIPPING_CARRIER, { params: { orderId: `${orderId}` } });
    if (carrierRes?.data?.length > 0) {
      let type = carrierRes?.data[0]?.carrier_code;
      if (type !== undefined) {
        if (type === 'fedex') openTrackingTab(FEDEX_TRACK_URL, `${trackingId}`);
        if (type === 'stamps_com') openTrackingTab(STAMPS_COM_TRACK_URL, `${trackingId}`);
        if (type === 'ups') openTrackingTab(UPS_TRACK_URL, `${trackingId}`);
      }
    } else {
      toast(formatMessage('Unable to track order'));
      return e.preventDefault();
    }
  };

  const handlePayInvoice = async (row: Order) => {};

  const handleOrderNumber = (row: Order) => {
    router('/home/prescriptions/order/' + row?.order?.orderId, { state: { data: 'orders' } });
  };

  const renderMedications = (data: any) => {
    const medLength = data?.length;
    const medName = data[0]?.name || '';
    return `${medName}${medLength > 2 ? ' +' + (Number(medLength) - 1) : ''}`;
  };

  // track order
  const handleViewTracking = (order: Order) => {
    if (order?.order?.trackingUrl) {
      window.open(order?.order?.trackingUrl, '_blank');
    }
  };

  const handleEnrollAutoRefill = (row: Order) => {
    setOrderID(row.order.orderId);
    setModel((oldData) => ({
      ...oldData,
      autoRefill: true
    }));
  };

  const handleEnrollAutoRefillClose = (res?: boolean) => {
    setModel((oldData) => ({
      ...oldData,
      autoRefill: false
    }));
  };

  const handleOpen = () => {
    setModel((oldData) => ({
      ...oldData,
      message: true
    }));
  };

  const handleClose = () => {
    setModel((oldData) => ({
      ...oldData,
      message: false
    }));
  };

  const handleCloseModel = () => {
    setModel((oldData) => ({
      ...oldData,
      message: false
    }));
  };

  const handleDeliveryDate = (res: Array<number>) => {
    setUnEnrolledIds(res);

    setModel((oldData) => ({
      ...oldData,
      orderDate: true,
      requestRefill: false
    }));
  };

  const handleOpenDeliveryAddress = (res?: String) => {
    if (res) {
      setSelectedDate(res);
    }
    setModel((oldData) => ({
      ...oldData,
      refillAddress: true
    }));
  };

  const handleOpenPay = () => {
    setModel((oldData) => ({
      ...oldData,
      payInfo: true
    }));
  };

  const handleCloseDateModel = () => {
    setModel((oldData) => ({
      ...oldData,
      orderDate: false
    }));
  };

  const handleRefillAddressClose = () => {
    setModel((oldData) => ({
      ...oldData,
      refillAddress: false
    }));
  };

  const handlePayClose = () => {
    setModel((oldData) => ({
      ...oldData,
      payInfo: false,
      requestRefill: false
    }));
  };

  const handleRequestRefill = (row: Order) => {
    setShippingAddress(row as Order);
    setOrderID(row.order.orderId);
    setOriginalShippingAddress(row.shippingAddress);

    setModel((prevModel) => ({
      ...prevModel,
      requestRefill: true
    }));
  };

  const handleRequestRefillClose = (res?: boolean) => {
    closeModel();
  };

  const openPayInfo = () => {
    setModel((oldData) => ({
      ...oldData,
      payInfo: true
    }));
  };

  return (
    <>
      {' '}
      {model.autoRefill && (
        <BootstrapDialog onClose={() => handleEnrollAutoRefillClose()} open={model.autoRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <AutoEnrollRefill handleClose={(res?: boolean) => handleEnrollAutoRefillClose(res)} orderId={Number(orderId)} />
        </BootstrapDialog>
      )}
      {model.payInfo && (
        <BootstrapDialog onClose={() => handlePayClose()} open={model.payInfo} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <PayInfo handleClose={(res?: boolean) => handlePayClose()} openPayInfo={openPayInfo} selectedDate={selectedDate} orderId={Number(orderId)} unEnrolledIds={unEnrolledIds} originalShippingAddress={originalShippingAddress} handleOpen={handleOpen} />
        </BootstrapDialog>
      )}
      {model.orderDate && (
        <BootstrapDialog onClose={() => handleCloseDateModel()} open={model.orderDate} PaperProps={{ style: { height: 'auto', minHeight: 500, width: 360, overflowX: 'auto' } }}>
          <OrderDeliveryDate handleClose={(res?: boolean) => handleCloseDateModel()} orderId={Number(orderId)} handleOpenDeliveryAddress={(res?: String) => handleOpenDeliveryAddress(res)} />
        </BootstrapDialog>
      )}
      {model.message && (
        <BootstrapDialog onClose={() => handleCloseModel()} open={model.message} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <MessagePopup handleClose={(res?: boolean) => handleCloseModel()} content="Your request has been sent in auto refill, please contact Customer Service for any changes." />
        </BootstrapDialog>
      )}
      {model.refillAddress && (
        <BootstrapDialog onClose={() => handleRefillAddressClose()} open={model.refillAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <RequestRefill handleClose={(res?: boolean) => handleRefillAddressClose()} handleOpen={(res?: boolean) => handleOpenPay()} orderId={Number(orderId)} rxId={rxId} originalShippingAddress={originalShippingAddress} />
        </BootstrapDialog>
      )}
      {model.requestRefill && (
        <BootstrapDialog onClose={() => handleRequestRefillClose()} open={model.requestRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <RequestRefillDetail handleClose={(res?: boolean) => handleRequestRefillClose(res)} handleDeliveryDate={(res: Array<number>) => handleDeliveryDate(res)} orderId={Number(orderId)} />
        </BootstrapDialog>
      )}
      <TableContainer component={Paper} className="table_customized_Prescription">
        <Table sx={{ minWidth: 700, minHeight: 120 }} stickyHeader>
          <TableHead className="table_head">
            <TableRow>
              <StyledTableCell onClick={() => requestSort('order.invoiceNumber')} className={getClassNamesFor('Order_Number')}>
                Order #
                <span>
                  <img className="right_arrow" src={TableArrow} alt="logo" height={10} width={10} />
                </span>
              </StyledTableCell>
              <StyledTableCell onClick={() => requestSort('doctor.firstName')} className={getClassNamesFor('Prescriber_Name')}>
                Prescriber
                <span>
                  <img className="right_arrow" src={TableArrow} alt="logo" height={10} width={10} />
                </span>
              </StyledTableCell>

              <StyledTableCell>Medication</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>

              <StyledTableCell onClick={() => requestSort('order.trackingNumber')} className={getClassNamesFor('Tracking')}>
                Tracking #
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody className="table_body">
            {items &&
              items.reverse().map((row: Order, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="td" className="table_first" onClick={() => handleOrderNumber(row)}>
                    <Stack>{row?.order?.invoiceNumber ? <Box className="table_first">{row?.order?.invoiceNumber}</Box> : null}</Stack>
                  </StyledTableCell>
                  <StyledTableCell component="td">
                    <Stack>{`${row?.doctor?.firstName} ${row?.doctor?.lastName}`}</Stack>
                  </StyledTableCell>

                  <StyledTableCell component="td">
                    <Stack className="td-text">{renderMedications(row?.prescriptions)}</Stack>
                  </StyledTableCell>

                  {/* Check for originalRefill and render empty cell if no button */}
                  <StyledTableCell component="td">
                    {row?.prescriptions.some((items) => items.originalRefill >= '1') ? (
                      <Stack>
                        <Button className="btn-css2 f-size" onClick={() => handleEnrollAutoRefill(row)}>
                          Auto Refill Management
                        </Button>
                      </Stack>
                    ) : (
                      <div style={{ height: '24px' }}></div> // Placeholder div
                    )}
                  </StyledTableCell>

                  {/* Check for refillLeft and render empty cell if no button */}
                  <StyledTableCell component="td">
                    {row?.prescriptions.some((items) => items.refillLeft >= '1') ? (
                      <Stack>
                        <Button className="btn-css2 f-size" onClick={() => handleRequestRefill(row)}>
                          Request Refill
                        </Button>
                      </Stack>
                    ) : (
                      <div style={{ height: '24px' }}></div> // Placeholder div
                    )}
                  </StyledTableCell>

                  <StyledTableCell component="td" className="table_first">
                    <Stack onClick={() => handleViewTracking(row)}>{row?.order?.trackingNumber ? row?.order?.trackingNumber : ''}</Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

            {(!items || items.length === 0) && (
              <StyledTableRow>
                <StyledTableCell component="td" className="NoDataFound">
                  <Stack>No Data Found</Stack>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
