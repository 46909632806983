import { FormBuilder, Validators } from 'react-reactive-form';

import { AUTH_DOCTOR_PORTAL, ADD_PATIENT_CARD, SET_DEFAULT_CARD } from '../../../constants/Endpoints';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api-noload';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import { toast } from 'react-toastify';
import { formatMessage } from '../../../constants/util';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';

export const addNewCardForm = FormBuilder.group({
  pat_id: ['', [Validators.required]],
  cc_number: ['', [Validators.required]],
  exp_date: ['', [Validators.required]],
  cvc: ['', [Validators.required]],
  cardholdername: ['', [Validators.required]],
  billingZc: ['', [Validators.required]],
  street: ['', Validators.required],
  city: ['', Validators.required],
  state: ['', Validators.required],
  country: ['US', Validators.required]
});

export const AddNewCardWithoutLoadFormHandler = async (event: React.SyntheticEvent, router: NavigateFunction) => {
  try {
    event.preventDefault();
    addNewCardForm.controls.pat_id.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cc_number.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.exp_date.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cvc.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cardholdername.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.billingZc.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.country.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewCardForm.invalid) {
      return;
    }

    let cardData: any = {
      pat_id: addNewCardForm?.value?.pat_id || '',
      default: true,
      number: addNewCardForm?.value?.cc_number?.replace(/\s/g, '') || '',
      cvc: addNewCardForm?.value?.cvc || '',
      expirationMonth: addNewCardForm?.value?.exp_date.split('/')[0] || '',
      expirationYear: `20${addNewCardForm?.value?.exp_date.split('/')[1]}` || '',
      billingAddress: {
        address1: addNewCardForm?.value?.street || '',
        state: addNewCardForm?.value?.state || '',
        city: addNewCardForm?.value?.city || '',
        zipCode: addNewCardForm?.value?.billingZc || ''
      },
      address: {
        address1: addNewCardForm?.value?.street || '',
        state: addNewCardForm?.value?.state || '',
        city: addNewCardForm?.value?.city || '',
        zipCode: addNewCardForm?.value?.billingZc || ''
      }
    };

    const addCardRes: AxiosResponse = await Patient.post(ADD_PATIENT_CARD, cardData);

    if (addCardRes.status === 201) {

      const cardId = addCardRes.data.cardId;

      toast(formatMessage('Card added successfully'));
      try {
        const res: AxiosResponse = await Patient.patch(`${SET_DEFAULT_CARD}/${cardId}`);
   
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast(formatMessage(err?.response.data.message));
         }
      }
      return 'OK';
    }
    return false;
  } catch (err: any) {
    if (Object.keys(err?.response?.data)) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

export const AddNewCardWithoutLoadFormHandlerAsGuest = async (event: React.SyntheticEvent, router: NavigateFunction) => {
  try {
    event.preventDefault();
    addNewCardForm.controls.pat_id.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cc_number.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.exp_date.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cvc.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.cardholdername.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.billingZc.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewCardForm.controls.country.markAsTouched({ emitEvent: true, onlySelf: true });

    let cardData: any = {
      pat_id: addNewCardForm?.value?.pat_id || '',
      default: true,
      number: addNewCardForm?.value?.cc_number?.replace(/\s/g, '') || '',
      cvc: addNewCardForm?.value?.cvc || '',
      expirationMonth: addNewCardForm?.value?.exp_date.split('/')[0] || '',
      expirationYear: `20${addNewCardForm?.value?.exp_date.split('/')[1]}` || '',
      billingAddress: {
        address1: addNewCardForm?.value?.street || '',
        state: addNewCardForm?.value?.state || '',
        city: addNewCardForm?.value?.city || '',
        zipCode: addNewCardForm?.value?.billingZc || ''
      },
      address: {
        address1: addNewCardForm?.value?.street || '',
        state: addNewCardForm?.value?.state || '',
        city: addNewCardForm?.value?.city || '',
        zipCode: addNewCardForm?.value?.billingZc || ''
      }
    };

    const addCardRes: AxiosResponse = await PatientWithoutToken.post(ADD_PATIENT_CARD, cardData);
    if (addCardRes.status === 201) {
      toast(formatMessage('Card added successfully'));
      let orders = JSON.parse(localStorage.getItem('Orders') || '[]');

      // Define the new card data (you can modify this as per your response structure)
      let newCard = {
        lastFourDigits: addCardRes.data.lastFourDigits, // Replace with actual data
        expiration: addCardRes.data.expiration, // Replace with actual data
        cardId: addCardRes.data.cardId, // Replace with actual data
        default: addCardRes.data.default, // Replace with actual data
        type: addCardRes.data.type // Replace with actual data
      };

      // Check if the orders array contains a 'cards' property and update it
      if (orders.cards) {
        // Add the new card to the cards array
        orders.cards.push(newCard);
      } else {
        // If no cards array exists, initialize it
        orders.cards = [newCard];
      }

      // Save the updated orders data back to localStorage
      localStorage.setItem('Orders', JSON.stringify(orders));
      return 'OK';
    }
    return false;
  } catch (err: any) {
    if (Object.keys(err?.response?.data)) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};
